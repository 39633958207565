import AppIcon from "../app-icon";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

export enum Sort {
  ASC = "asc",
  DESC = "desc",
}

interface Props {
  sort?: string;
}

export function SortIndicator(props: Props) {
  const { sort } = props;

  let icon = faSort;
  if (sort === Sort.ASC) {
    icon = faSortUp;
  } else if (sort === Sort.DESC) {
    icon = faSortDown;
  }
  return <AppIcon icon={icon} standardLeftMargin />;
}
