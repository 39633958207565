import { useToast } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContentStrategyReviewQuery, useSaveContentStrategyReviewMutation } from "../../../../generated/graphql";
import { handleGraphQLResponseError } from "../../../../util/error-helper";
import { AuthContext } from "../../../../core/auth-manager";

interface Options {
  taskId: number;
}

interface RevisionRequest {
  orderContentStrategyId: number;
  comment: string;
}

export function useContentStrategyReview(options: Options) {
  const { taskId } = options;

  const toast = useToast();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const queryResult = useContentStrategyReviewQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId,
    },
  });
  const [saveContentStrategyReviewMutation] = useSaveContentStrategyReviewMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [idBeingEdited, setIdBeingEdited] = useState<null | number>(null);
  const [revisionRequests, setRevisionRequests] = useState<RevisionRequest[]>([]);
  const [comment, setComment] = useState("");

  const task = queryResult.data?.task ?? null;
  const customerProposal = task?.customerProposal ?? null;
  const orderContentStrategies = customerProposal?.orderContentStrategies ?? [];

  async function handleSubmit() {
    try {
      setIsSubmitting(true);
      const response = await saveContentStrategyReviewMutation({
        variables: {
          input: {
            revisionRequests,
            taskId,
          },
        },
      });

      if (!response.data?.saveContentStrategyReview.ok) {
        throw new Error(
          response.data?.saveContentStrategyReview.error?.message ??
            "Something went wrong while submitting the review. Please try again."
        );
      }
      toast({
        title: "Review Completed",
        description: "The content strategy review was submitted successfully.",
        status: "success",
      });
      navigate("/");
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleRequestRevision(orderContentStrategyId: number) {
    setComment(revisionRequests.find((x) => x.orderContentStrategyId === orderContentStrategyId)?.comment ?? "");
    setIdBeingEdited(orderContentStrategyId);
  }

  function cancelRequestRevision() {
    setIdBeingEdited(null);
    setComment("");
  }

  function saveRevisionRequest() {
    if (!idBeingEdited) {
      return;
    }

    const revision: RevisionRequest = {
      comment,
      orderContentStrategyId: idBeingEdited,
    };

    setRevisionRequests([...revisionRequests.filter((x) => x.orderContentStrategyId !== idBeingEdited), revision]);
    cancelRequestRevision();
  }

  function removeRevisionRequest(orderContentStrategyId: number) {
    setRevisionRequests(revisionRequests.filter((x) => x.orderContentStrategyId !== orderContentStrategyId));
  }

  function handleDownloadCSV() {
    window.open(
      `${process.env.REACT_APP_API_BASE}/files/customers/${authContext.customer?.id ?? 0}/tasks/${taskId}`,
      "_blank"
    );
  }

  return {
    loading: queryResult.loading,
    taskId,
    task,
    customerProposal,
    orderContentStrategies,
    handleSubmit,
    isSubmitting,
    handleRequestRevision,
    cancelRequestRevision,
    idBeingEdited,
    revisionRequests,
    saveRevisionRequest,
    comment,
    setComment,
    removeRevisionRequest,
    handleDownloadCSV,
  };
}
