import TaskWorkspace from "./task-workspace";
import { useParams } from "react-router";
import { Navigate } from "react-router-dom";

export function TaskRoute() {
  const { id } = useParams<{ id: string }>();
  if (!id) {
    console.error('Unable to find required "id" parameter.');
    return <Navigate to="/" />;
  }
  const taskId = parseInt(id, 10);
  return <TaskWorkspace taskId={taskId} />;
}

export default TaskRoute;
