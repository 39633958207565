import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useToast, Text, Box, Heading, Stack, Flex } from "@chakra-ui/react";
import {
  useKeywordResearchReviewTaskWorkspaceQuery,
  useSubmitKeywordReviewMutation,
  RejectedKeyword,
} from "../../../../generated/graphql";
import Loader from "../../../../components/loader";
import { TOAST_DURATION, TOAST_POSITION } from "../../../../constants/constants";
import { PanelGroup, PanelTop, PanelBottom } from "../../../../components/panels";
import { Table, TableHead, TableRow, TableHeaderCell, TableBody } from "../../../../components/table";
import { PrimaryButton } from "../../../../components/buttons";
import { ProposedKeywordRow } from "./proposed-keyword-row";
import { RichContent } from "../../../../components/rich-content";

interface Props {
  taskId: number;
}

function KeywordResearchReview(props: Props) {
  const { taskId } = props;
  const toast = useToast();
  const navigate = useNavigate();

  const [rejectedKeywords, setRejectedKeywords] = useState<RejectedKeyword[]>([]);

  const { data, loading, error } = useKeywordResearchReviewTaskWorkspaceQuery({
    variables: {
      taskId,
    },
    fetchPolicy: "network-only",
  });

  const [submitReview, { loading: submitLoading }] = useSubmitKeywordReviewMutation();

  async function onSubmit() {
    const response = await submitReview({
      variables: {
        input: {
          taskId,
          customerKeywordProposalId: data?.task?.keywordReview?.id ?? 0,
          rejectedKeywords,
        },
      },
    });

    if (response?.data?.submitKeywordReview.done) {
      toast({
        title: "Completed",
        description: "The Keyword Review was submitted successfully.",
        status: "success",
      });
    }

    navigate("/");
  }

  function onRejected(proposedCustomerKeywordId: number, reason: string) {
    setRejectedKeywords([
      ...rejectedKeywords.filter((x) => x.proposedCustomerKeywordId !== proposedCustomerKeywordId),
      { proposedCustomerKeywordId, reason },
    ]);
  }

  function onUndo(proposedCustomerKeywordId: number) {
    setRejectedKeywords(rejectedKeywords.filter((x) => x.proposedCustomerKeywordId !== proposedCustomerKeywordId));
  }
  if (loading) {
    return <Loader type="fullView" message="Loading Task" />;
  }

  if (error) {
    toast({
      title: "Error",
      position: TOAST_POSITION,
      description: error.message,
      status: "error",
      duration: TOAST_DURATION,
      isClosable: true,
    });
    return <Navigate to="/" />;
  }

  if (!data?.task?.keywordReview?.id) {
    toast({
      title: "Error",
      position: TOAST_POSITION,
      description: "Something went wrong retrieving the task. Please try again.",
      status: "error",
      duration: TOAST_DURATION,
      isClosable: true,
    });
    return <Navigate to="/" />;
  }

  const isCompleted = data.task.status === "completed";
  const primaryProposedKeywords = data.task.keywordReview.primaryProposedKeywords ?? [];
  const haloProposedKeywords = data.task.keywordReview.haloProposedKeywords ?? [];
  const summary = data.task.keywordReview.summary ?? "";

  return (
    <Stack spacing={8}>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Flex justifyContent="space-between">
            <Box>
              <Heading size="md">Primary Keyword Research</Heading>
              <Text fontSize="small">Primary keywords are the target terms that are used when doing work.</Text>
            </Box>
            <Box textAlign="right">
              {!isCompleted && (
                <PrimaryButton onClick={onSubmit} isLoading={submitLoading}>
                  {rejectedKeywords.length === 0 ? "Approve All" : "Submit"}
                </PrimaryButton>
              )}
            </Box>
          </Flex>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isLoading={loading} isEmpty={primaryProposedKeywords.length === 0}>
            <TableHead>
              <TableRow key="header">
                <TableHeaderCell>Keyword</TableHeaderCell>
                <TableHeaderCell>Location</TableHeaderCell>
                <TableHeaderCell>Country</TableHeaderCell>
                <TableHeaderCell>Landing Page URL</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {primaryProposedKeywords.map((keyword) => (
                <ProposedKeywordRow
                  key={keyword.id}
                  onRejected={onRejected}
                  onUndo={onUndo}
                  proposedKeyword={keyword}
                  rejectDisabled={isCompleted}
                  rejectedKeyword={rejectedKeywords.find((x) => x.proposedCustomerKeywordId === keyword.id) ?? null}
                />
              ))}
            </TableBody>
          </Table>
        </PanelBottom>
      </PanelGroup>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Heading size="md">Halo Keyword Research</Heading>
          <Text fontSize="small">
            Halo keywords are terms that are related to your primary keywords, but will not be targeted specifically
            when doing work. Halo Keywords will be monitored for rankings.
          </Text>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isLoading={loading} isEmpty={haloProposedKeywords.length === 0}>
            <TableHead>
              <TableRow key="header">
                <TableHeaderCell>Keyword</TableHeaderCell>
                <TableHeaderCell>Location</TableHeaderCell>
                <TableHeaderCell>Country</TableHeaderCell>
                <TableHeaderCell>Landing Page URL</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {haloProposedKeywords.map((keyword) => (
                <ProposedKeywordRow
                  key={keyword.id}
                  onRejected={onRejected}
                  onUndo={onUndo}
                  proposedKeyword={keyword}
                  rejectDisabled={isCompleted}
                  rejectedKeyword={rejectedKeywords.find((x) => x.proposedCustomerKeywordId === keyword.id) ?? null}
                />
              ))}
            </TableBody>
          </Table>
        </PanelBottom>
      </PanelGroup>
      {summary && (
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Heading size="md">Keyword Summary</Heading>
            <Text fontSize="small">A summary explanation sub text</Text>
          </PanelTop>
          <PanelBottom>
            <RichContent content={summary} />
          </PanelBottom>
        </PanelGroup>
      )}
    </Stack>
  );
}

export default KeywordResearchReview;
