import { Link, Text } from "@chakra-ui/react";
import { useActionItemsBarQuery } from "../../generated/graphql";
import { AlertWarning } from "../../components/alerts";

export function ActionItemsBar() {
  const { data, loading, error } = useActionItemsBarQuery({
    fetchPolicy: "network-only",
  });

  const tasks = data?.pendingTasks ?? [];

  if (loading || error || tasks.length === 0) {
    return null;
  }

  return (
    <>
      {tasks.map((task) => (
        <AlertWarning key={task.id}>
          <Text>
            You have a pending {task.taskType.name}.{" "}
            <Link color="#2F80ED" href={`/tasks/${task.id}`}>
              Review Now
            </Link>
          </Text>
        </AlertWarning>
      ))}
    </>
  );
}
