import ReactHelmet from "react-helmet";
import { Global } from "@emotion/react";

interface Props {
  children: React.ReactNode;
  pageTitle: string;
}

export function StandardLayoutV2(props: Props) {
  const { children, pageTitle } = props;

  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F6F8" } }} />
      <ReactHelmet>
        <title>{pageTitle}</title>
      </ReactHelmet>
      {children}
    </>
  );
}
