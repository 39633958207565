import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import KeywordsTable from "./keyword-table";
import SearchBox from "../../components/search-box";
import { MainNav, StandardLayoutV2 } from "../../components/layout-v2";
import { Container } from "../../components/layout-v2/container";
import { PanelGroup, PanelMiddle, PanelTop } from "../../components/panels";
import { PrimaryButton } from "../../components/buttons";
import { ActionItemsBar } from "../../common/action-items-bar";
import { useKeywordRankings } from "./use-keyword-rankings";
import KeywordMetrics from "./keyword-metrics";
import { KeywordRankingContext } from "./keyword-ranking-context";
import { SEARCH_ENGINES } from "../../constants/search-engines";
import { CustomSingleSelect } from "../../common/custom-selector";

function KeywordRankingsRoute() {
  const {
    keywordQueryResult,
    keywordRankingsQueryResult,
    keywordMetricsQueryResult,
    selectedKeyword,
    pagination,
    sort,
    keyword,
    search,
    searchEngine,
    onSort,
    onSearchChange,
    onDownloadPDF,
    onKeywordSelect,
    onSearchEngineChange,
  } = useKeywordRankings();

  const searchEngineItems = [
    {
      value: SEARCH_ENGINES.GOOGLE,
      displayText: "Organic",
    },
    {
      value: SEARCH_ENGINES.GOOGLE_MAP,
      displayText: "Map",
    },
  ];

  return (
    <StandardLayoutV2 pageTitle="Keyword Ranking">
      <MainNav />
      <KeywordRankingContext.Provider
        value={{
          keywordQueryResult,
          keywordRankingsQueryResult,
          keywordMetricsQueryResult,
          selectedKeyword,
          pagination,
          sort,
          keyword,
          search,
          onSort,
          onKeywordSelect,
        }}
      >
        <Container centerHorizontally>
          <ActionItemsBar />
          <PanelGroup marginTop={4} border={0}>
            {keywordMetricsQueryResult.data?.customer?.hasMapRanking && (
              <Box marginY={4}>
                <CustomSingleSelect value={searchEngine} onChange={onSearchEngineChange} items={searchEngineItems} />
              </Box>
            )}
            <KeywordMetrics />
          </PanelGroup>
          <PanelGroup>
            <PanelTop>
              <Flex justifyContent="space-between">
                <Box width={{ base: "100%", md: "50%" }}>
                  <SearchBox onChange={onSearchChange} placeholder="Search" />
                </Box>
                <Stack isInline spacing={2} justifyContent="flex-end">
                  <PrimaryButton onClick={onDownloadPDF}>Export PDF</PrimaryButton>
                </Stack>
              </Flex>
            </PanelTop>
            <PanelMiddle>
              <Text fontSize="lg">Keywords</Text>
            </PanelMiddle>
            <PanelMiddle containsTable>
              <KeywordsTable />
            </PanelMiddle>
          </PanelGroup>
        </Container>
      </KeywordRankingContext.Provider>
    </StandardLayoutV2>
  );
}

export default KeywordRankingsRoute;
