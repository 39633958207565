import { useContext } from "react";
import { AuthContext } from "../../../core/auth-manager";
import { MainNavSmallLink } from "./main-nav-small-link";
import { Box } from "@chakra-ui/react";
import { useCustomersQuery } from "../../../generated/graphql";

export function MainNavSmallProfile() {
  const authContext = useContext(AuthContext);
  const { viewer } = authContext;
  const queryResult = useCustomersQuery({
    variables: {
      offset: 0,
      limit: 2,
    },
    fetchPolicy: "network-only",
  });
  const hasMultipleCustomers = (queryResult.data?.customers.totalCount ?? 0) > 1;

  return (
    <Box>
      <Box marginBottom={4}>
        <span style={{ display: "block" }}>{viewer?.user?.fullName ?? "Not Logged In"}</span>
        <span style={{ display: "block", fontSize: ".75rem" }}>{authContext.customer?.name ?? ""}</span>
      </Box>
      <Box marginBottom={2}>
        <MainNavSmallLink to="/profile" name="Profile" isActive={false} />
      </Box>
      <Box marginBottom={2}>
        <MainNavSmallLink to="/account" name="Account Setup" isActive={false} />
      </Box>
      {hasMultipleCustomers && (
        <Box marginBottom={2}>
          <MainNavSmallLink to="/select-client" name="Switch Client" isActive={false} />
        </Box>
      )}
      <Box marginBottom={2}>
        <MainNavSmallLink
          onClick={() => {
            authContext.logout();
          }}
          name="Logout"
          isActive={false}
          to=""
        />
      </Box>
    </Box>
  );
}
