import { useCustomerAnalyticsQuery } from "../../generated/graphql";
import { ActionItemsBar } from "../../common/action-items-bar";

import { StandardLayoutV2, MainNav } from "../../components/layout-v2";
import { Container } from "../../components/layout-v2/container";
import { PageTitle } from "../../components/page-title";
import { PanelBox } from "../../components/panels";
import Loader from "../../components/loader";
import { LOADER_TYPE } from "../../constants/constants";
import { Navigate } from "react-router-dom";

function AnalyticsRoute() {
  const { data, loading } = useCustomerAnalyticsQuery({
    fetchPolicy: "cache-and-network",
  });

  const src = data?.customer?.analyticsReportingInlineFrameUrl ?? "";

  if (!loading && data?.customer?.id && !src) {
    return <Navigate to="/" />;
  }

  return (
    <StandardLayoutV2 pageTitle="Account Setup">
      <MainNav />
      <Container centerHorizontally height={loading ? "100%" : "85vh"}>
        <ActionItemsBar />
        <PageTitle>Analytics</PageTitle>
        <PanelBox height="100%">
          {loading ? (
            <Loader type={LOADER_TYPE.table} />
          ) : (
            <iframe title="Google Data Studio" src={src} allowFullScreen loading="eager" width="100%" height="100%" />
          )}
        </PanelBox>
      </Container>
    </StandardLayoutV2>
  );
}

export default AnalyticsRoute;
