import { Text, Stack, Image } from "@chakra-ui/react";
import RobotThinking from "../assets/robot-thinking.svg";

const NoDataAvailable = () => (
  <Stack spacing={2} justifyContent="center" width="100%" textAlign="center">
    <Image src={RobotThinking} maxH={50} />
    <Text>No data was returned.</Text>
  </Stack>
);

export default NoDataAvailable;
