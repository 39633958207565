import { StandardLayoutV2, MainNav } from "../../components/layout-v2";
import { Container } from "../../components/layout-v2/container";
import { PageTitle } from "../../components/page-title";
import { Flex, Stack } from "@chakra-ui/react";
import { SecondaryButton } from "../../components/buttons";
import { WorkSummaryMonth } from "./work-summary-month";
import { useWorkSummary } from "./use-work-summary";
import Loader from "../../components/loader";
import { LOADER_TYPE } from "../../constants/constants";
import NoDataAvailable from "../../components/no-data-available";
import { PanelBox } from "../../components/panels";
import { ActionItemsBar } from "../../common/action-items-bar";

export function WorkSummaryRoute() {
  const { loading, workSummaryMonths, showHistory, toggleShowHistory, onDownloadWorkSummaryCSV } = useWorkSummary();
  return (
    <StandardLayoutV2 pageTitle="Work Summary">
      <MainNav />
      <Container centerHorizontally>
        <ActionItemsBar />
        <Flex justifyContent="space-between" alignItems="center">
          <PageTitle>Work Summary</PageTitle>
          <Flex>
            <SecondaryButton onClick={onDownloadWorkSummaryCSV} mx={4}>
              Download CSV
            </SecondaryButton>
            <SecondaryButton onClick={toggleShowHistory}>
              {showHistory ? "Hide History" : "View History"}
            </SecondaryButton>
          </Flex>
        </Flex>
        {loading && <Loader type={LOADER_TYPE.table} />}
        {!loading && workSummaryMonths.length === 0 && (
          <PanelBox>
            <NoDataAvailable />
          </PanelBox>
        )}
        {!loading && workSummaryMonths.length > 0 && (
          <Stack spacing={4}>
            {workSummaryMonths.map((workSummaryMonth) => (
              <WorkSummaryMonth
                key={workSummaryMonth.name}
                isCurrent={workSummaryMonth.isCurrent}
                isPast={workSummaryMonth.isPast}
                monthName={workSummaryMonth.name}
                orders={workSummaryMonth.orders}
                amTasks={workSummaryMonth.amTasks}
              />
            ))}
          </Stack>
        )}
      </Container>
    </StandardLayoutV2>
  );
}
