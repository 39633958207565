import { Box, Text, Progress, Flex } from "@chakra-ui/react";
import { PageTitle } from "../../components/page-title";
import { StandardLayoutV2, MainNav } from "../../components/layout-v2";
import { Container } from "../../components/layout-v2/container";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { CompanyDetails } from "./company-details";
import { ContentDetails } from "./content-details";
import { WebsiteDetails } from "./website-details";
import { useClientDetailsQuestionnaire } from "./use-client-questionnaire";
import { useClientAccount } from "./use-client-account";
import { ActionItemsBar } from "../../common/action-items-bar";
// import { HoursOfOperation } from "./hours-of-operation";
// import { CompanyImages } from "./company-images";

function AccountRoute() {
  const { questionnaireLength, questionnaireValidCount, ...questionnaire } = useClientDetailsQuestionnaire();
  const { customerAccountLength, customerAccountValidCount, ...customerAccount } = useClientAccount();

  const progress =
    ((questionnaireValidCount + customerAccountValidCount) / Math.max(1, questionnaireLength + customerAccountLength)) *
    100;

  return (
    <StandardLayoutV2 pageTitle="Account Setup">
      <MainNav />
      <Container centerHorizontally>
        <ActionItemsBar />
        <PageTitle>Account Setup</PageTitle>
        <PanelGroup marginBottom={0}>
          <PanelTop showBottomBorder>
            <Text fontSize="lg" fontWeight="bold">
              {`Let's get to know you better`}
            </Text>
            <Text fontSize="xs" color="#828282">
              Complete these tasks at your own pace to get your account up and running.
            </Text>
          </PanelTop>
        </PanelGroup>
        <PanelBottom position="sticky" zIndex={999} top="64px" marginBottom={4}>
          <Box display="flex" justifyContent="space-between">
            <Text fontSize="sm" color="#828282">
              Progress
            </Text>
            <Text fontSize="sm">{`${progress.toFixed(0)}%`}</Text>
          </Box>
          <Progress color="green" size="lg" value={progress} marginTop={1} />
        </PanelBottom>
        <Flex justifyContent={{ base: "stretch", md: "space-between" }} flexDir={{ base: "column-reverse", md: "row" }}>
          <Box width={{ base: "100%", md: "49%" }}>
            <CompanyDetails
              detailsFormik={customerAccount.detailsFormik}
              isOverviewLoading={customerAccount.isOverviewLoading}
            />
            <WebsiteDetails
              websiteFormik={questionnaire.websiteFormik}
              isQuestionnaireLoading={questionnaire.isQuestionnaireLoading}
            />
            {/* <HoursOfOperation /> */}
          </Box>
          <Box width={{ base: "100%", md: "49%" }}>
            <ContentDetails
              contentFormik={questionnaire.contentFormik}
              isQuestionnaireLoading={questionnaire.isQuestionnaireLoading}
            />
            {/* <CompanyImages /> */}
          </Box>
        </Flex>
      </Container>
    </StandardLayoutV2>
  );
}

export default AccountRoute;
