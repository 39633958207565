import { useState } from "react";

import { WorkSummary_OrderFragment } from "../../generated/graphql";

export function useWorkSummaryMonth() {
  const [orderLink, setOrderLink] = useState<WorkSummary_OrderFragment | null>(null);

  function onOpenLinkModal(order: WorkSummary_OrderFragment | null) {
    setOrderLink(order);
  }

  function onCloseLinkModal() {
    setOrderLink(null);
  }

  return {
    orderLink,
    onOpenLinkModal,
    onCloseLinkModal,
  };
}
