import React, { Component } from "react";
import { Box, Stack, Grid } from "@chakra-ui/react";

interface Props {
  children: React.ReactNode;
}

class LoginContainer extends Component<Props> {
  state = {};

  render() {
    const { children } = this.props;

    return (
      <Box as="main" paddingX={4} marginX="auto">
        <Stack spacing={0} direction="column" alignItems="center" justify="center" minHeight="100vh" flexWrap={"wrap"}>
          <Grid w="30%">
            <Box>{children}</Box>
          </Grid>
        </Stack>
      </Box>
    );
  }
}

export default LoginContainer;
