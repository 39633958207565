import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { Heading, Box } from "@chakra-ui/react";
import { Dashboard_ConversationFragment } from "../../generated/graphql";
import { Table, TableRow, TableBody, TableCell } from "../../components/table";
import AppLink from "../../components/app-link";
import AppIcon from "../../components/app-icon";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/loader";
import { LOADER_TYPE } from "../../constants/constants";

interface Props {
  conversations: Dashboard_ConversationFragment[];
  loading?: boolean;
}

export function DashboardMessages(props: Props) {
  const { conversations, loading } = props;

  if (loading) {
    return <Loader type={LOADER_TYPE.table} />;
  }

  return (
    <PanelGroup>
      <PanelTop showBottomBorder>
        <Heading size="md">Messages</Heading>
      </PanelTop>
      <PanelBottom containsTable>
        <Table isLoading={false} isEmpty={conversations.length === 0}>
          <TableBody>
            {conversations.map((conversation) => (
              <TableRow key={conversation.id}>
                <TableCell>
                  <AppLink to={`/messages/${conversation.id}`}>{conversation.subject}</AppLink>
                </TableCell>
              </TableRow>
            ))}
            {conversations.length > 0 && (
              <TableRow key="view-all">
                <TableCell>
                  <Box textAlign="center">
                    <AppLink to="/messages">
                      View all
                      <AppIcon icon={faAngleRight} standardLeftMargin />
                    </AppLink>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </PanelBottom>
    </PanelGroup>
  );
}
