import { useContext, useMemo } from "react";
import { NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params";

import {
  CustomersKeywordSort,
  useCustomerKeywordsQuery,
  useKeywordMetricsQuery,
  useKeywordRankingsQuery,
} from "../../generated/graphql";
import { usePagination } from "../../util/use-pagination";
import { PAGE_LIMIT } from "../../constants/constants";
import { AuthContext } from "../../core/auth-manager";
import { SEARCH_ENGINES } from "../../constants/search-engines";

export function useKeywordRankings() {
  const [search, setSearch] = useQueryParam("search", StringParam);
  const pagination = usePagination(PAGE_LIMIT);
  const [keyword, setKeyword] = useQueryParam("keyword", NumberParam);
  const [sort, setSort] = useQueryParam("sort", withDefault(StringParam, CustomersKeywordSort.LatestRankAsc));
  const [searchEngine, setSearchEngine] = useQueryParam(
    "searchEngine",
    withDefault(StringParam, SEARCH_ENGINES.GOOGLE)
  );
  const authContext = useContext(AuthContext);

  const keywordQueryResult = useCustomerKeywordsQuery({
    variables: {
      limit: PAGE_LIMIT,
      offset: pagination.offset,
      search,
      sort: sort ? (sort as CustomersKeywordSort) : undefined,
      searchEngine,
      isTrackingMapRank: searchEngine === SEARCH_ENGINES.GOOGLE_MAP ? true : undefined,
    },
    fetchPolicy: "network-only",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customerKeywords = keywordQueryResult.data?.customerKeywords.nodes ?? [];
  const selectedKeyword = useMemo(() => {
    return customerKeywords.find((x) => x.id === keyword);
  }, [customerKeywords, keyword]);

  const createdAt = useMemo(() => {
    return {
      gte: selectedKeyword?.startRank?.createdAt,
      lte: new Date(),
    };
  }, [selectedKeyword]);

  const keywordRankingsQueryResult = useKeywordRankingsQuery({
    variables: {
      createdAt,
      customerKeywordId: selectedKeyword?.id ?? 0,
      searchEngine,
    },
    fetchPolicy: "network-only",
    skip: !selectedKeyword,
  });

  const keywordMetricsQueryResult = useKeywordMetricsQuery({
    variables: {
      searchEngine,
    },
    fetchPolicy: "network-only",
  });

  function onKeywordSelect(keywordId: number) {
    setKeyword(keywordId);
  }

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
    pagination.resetOffset();
  }

  function onSort(value: CustomersKeywordSort) {
    setSort(value);
    pagination.resetOffset();
  }

  function onSearchEngineChange(value: string | null) {
    setSearchEngine(value);
  }

  function onDownloadPDF() {
    window.open(
      `${process.env.REACT_APP_API_BASE}/files/customers/${authContext.customer?.id ?? 0}/keywords`,
      "_blank"
    );
  }

  return {
    keywordQueryResult,
    keywordRankingsQueryResult,
    keywordMetricsQueryResult,
    customerKeywords,
    selectedKeyword,
    pagination,
    searchEngine,
    sort,
    keyword,
    search: search ?? "",
    onSort,
    onSearchChange,
    onDownloadPDF,
    onKeywordSelect,
    onSearchEngineChange,
  };
}
