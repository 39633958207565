export const ORDER_STATUS = {
  // NEW is now deprecated, use scheduled instead
  NEW: {
    id: "new",
    name: "new",
  },
  // SCHEDULED is the default status
  SCHEDULED: {
    id: "scheduled",
    name: "Scheduled",
  },
  PENDING: {
    id: "pending",
    name: "Pending",
  },
  IN_PROGRESS: {
    id: "in_progress",
    name: "In Progress",
  },
  COMPLETED: {
    id: "completed",
    name: "Completed",
  },
  CANCELLED: {
    id: "cancelled",
    name: "Cancelled",
  },
  PAUSED: {
    id: "paused",
    name: "paused",
  },
};
