import { useContext } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import Loader from "../../components/loader";
import { LOADER_TYPE } from "../../constants/constants";
import ErrorCard from "../../components/error-card";
import { MetricBox } from "../../components/metric-box";
import { faBalanceScale, faChartLine, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { AppColors } from "../../core/custom-theme";
import NoDataAvailable from "../../components/no-data-available";
import { KeywordRankingContext } from "./keyword-ranking-context";

export default function KeywordMetrics() {
  const { keywordMetricsQueryResult } = useContext(KeywordRankingContext);

  const { loading, error, data } = keywordMetricsQueryResult ?? {};
  const { keywordsOnFirstPage, keywordsImproved, averageRanking } = data?.customer ?? {};

  return (
    <SimpleGrid columns={3} gridGap={2}>
      {loading && <Loader type={LOADER_TYPE.content} />}
      {error && <ErrorCard error={error} />}
      {!data?.customer && <NoDataAvailable />}
      {!loading && !error && (
        <>
          <MetricBox
            name="Keywords On First Page"
            value={`${keywordsOnFirstPage ?? "No Data Available"}`}
            icon={faTrophy}
            textColor={AppColors.primary.light}
          />
          <MetricBox
            name="Total Keywords Improved"
            value={`${keywordsImproved ?? "No Data Available"}`}
            icon={faChartLine}
            textColor={AppColors.primary.light}
          />
          <MetricBox
            name="Average Ranking for All Keywords"
            value={`${averageRanking?.toFixed(2) ?? "No Data Available"}`}
            icon={faBalanceScale}
            textColor={AppColors.primary.light}
          />
        </>
      )}
    </SimpleGrid>
  );
}
