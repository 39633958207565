export const LOADER_TYPE = {
  content: "content",
  table: "table",
  fullView: "fullView",
};
export const TOAST_POSITION = "top-right";
export const TOAST_DURATION = 3000;
export const PAGE_LIMIT = 50;
export const DATE_FORMAT = "yyyy-MM-dd";
export const DATE_TEXT_FORMAT = "MMM d";
export const DATE_TIME_FORMAT = "ff";
export const MMM_D_YYYY = "MMM d, yyyy";
export const MMM_D_YYYY_H_MM_A = "MMM d, yyyy h:mm a";

export const CUSTOMER_COOKIE_NAME = `${process.env.REACT_APP_ENVIRONMENT_PREFIX}:customer`;

export const TASK_TYPES = {
  KEYWORD_RESEARCH_CUSTOMER_REVIEW: "keyword-research-customer-review",
  CUSTOMER_CONTENT_REVIEW: "customer-content-review",
  CONTENT_CALENDAR_CUSTOMER_REVIEW: "content-calendar-customer-review",
  CONTENT_STRATEGY_CUSTOMER_REVIEW: "content-strategy-customer-review",
};
