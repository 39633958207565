import { useParams } from "react-router-dom";

import Loader from "../../components/loader";
import { LOADER_TYPE } from "../../constants/constants";
import { useDeliverableFileQuery } from "../../generated/graphql";
import NotFoundRouteHandler from "../not-found";

export function DeliverableFileRoute() {
  const { order, file } = useParams<"order" | "file">();

  const queryResult = useDeliverableFileQuery({
    fetchPolicy: "network-only",
    variables: {
      fileId: file ?? "",
      orderId: parseInt(order ?? ""),
    },
    skip: !order || !file,
  });

  if (queryResult.loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  if (queryResult.data?.deliverableFile?.url) {
    window.location.assign(queryResult.data.deliverableFile.url);
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  return <NotFoundRouteHandler />;
}
