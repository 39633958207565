import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Text,
  Textarea,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useCreateConversationMutation } from "../../generated/graphql";
import { TOAST_DURATION, TOAST_POSITION } from "../../constants/constants";
import { PrimaryButton } from "../../components/buttons";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
function ConversationCreateDialog(props: Props) {
  const { isOpen, onClose } = props;
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errorSubject, setErrorSubject] = useState("");
  const [createConversation, { loading }] = useCreateConversationMutation();
  const toast = useToast();
  const navigate = useNavigate();

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (value.length > 255) {
      setErrorSubject("Subject length should be less than 255.");
      return;
    } else {
      setErrorSubject("");
    }
    setSubject(value);
  }

  function onMessageChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setMessage(event.target.value);
  }

  async function onSend() {
    const response = await createConversation({
      variables: {
        input: {
          subject,
          content: message,
        },
      },
    });

    if (response?.data?.createConversation?.conversation?.id) {
      toast({
        title: "Conversation Started",
        position: TOAST_POSITION,
        description: "You have started a conversation", // TODO: Change message
        status: "success",
        duration: TOAST_DURATION,
        isClosable: true,
      });
      onClose();
      navigate(`/messages/${response.data.createConversation.conversation.id}`);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Message</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="8px">Subject</Text>
          <Input
            isInvalid={errorSubject.length > 0}
            errorBorderColor="crimson"
            pr="4.5rem"
            value={subject}
            onChange={onChange}
            my={1}
            autoFocus
          />
          {errorSubject.length > 0 && (
            <Text fontSize="sm" color="crimson" my={1}>
              {errorSubject}
            </Text>
          )}
          <Box my={1}>
            <Text mb="8px">Message</Text>
            <Textarea onChange={onMessageChange} resize="none" />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose} size="sm">
            Cancel
          </Button>
          <PrimaryButton
            size="sm"
            variant="solid"
            colorScheme="blue"
            isDisabled={
              loading || subject.trim().length === 0 || message.trim().length === 0
            } /*  TODO: restriction on message length */
            onClick={onSend}
          >
            Send
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConversationCreateDialog;
