import * as yup from "yup";
import {
  useCustomerAccountQuery,
  useSaveCustomerQuestionnaireMutation,
  useUpdateCustomerMutation,
} from "../../generated/graphql";
import { FormikHelpers, useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { handleFormGraphQLError } from "../../util/error-helper";
import { processNullableString } from "../../util/form-helpers";
import { useClientDetailsQuestionnaire } from "./use-client-questionnaire";

interface DetailsFormValues {
  name: string;
  website: string;
  categoryId: number | null;
  customerMarketSize: string;
  businessDescription: string;
  productsOffered: string;
  serviceArea: string;
}

const detailsSchema = yup.object().shape({
  name: yup.string().label("Company Name").required().nullable(false).max(255),
  website: yup.string().label("Company URL").required().nullable(false).url(),
  categoryId: yup
    .number()
    .label("Business Category")
    .required()
    .min(1, "Business Category is a required field")
    .nullable(false),
  customerMarketSize: yup.string().label("Customer Market Size").required(),
  businessDescription: yup.string().label("Business Description").required(),
  productsOffered: yup.string().label("Products Offered").required(),
  serviceArea: yup.string().label("Service Area").required(),
  instructions: yup.string().label("Instructions").notRequired(),
});

export function useClientAccount() {
  const customerAccountQueryResult = useCustomerAccountQuery({
    fetchPolicy: "network-only",
  });
  const {
    customerQuestionnaire,
    refetch: customerQuestionnaireRefetch,
    isQuestionnaireLoading,
  } = useClientDetailsQuestionnaire();

  const [updateCustomerMutation] = useUpdateCustomerMutation();
  const [saveCustomerQuestionnaireMutation] = useSaveCustomerQuestionnaireMutation();
  const toast = useToast();

  const customer = customerAccountQueryResult.data?.customer ?? null;

  async function onSubmit(values: DetailsFormValues, formikHelpers: FormikHelpers<DetailsFormValues>) {
    try {
      const response = await updateCustomerMutation({
        variables: {
          input: {
            categoryId: values.categoryId ?? undefined,
            name: values.name,
            website: processNullableString(values.website),
          },
        },
      });

      if (response.data?.updateCustomer.customer?.id) {
        await customerAccountQueryResult.refetch();
      }

      const questionnaireResponse = await saveCustomerQuestionnaireMutation({
        variables: {
          input: {
            businessDescription: processNullableString(values.businessDescription),
            customerMarketSize: processNullableString(values.customerMarketSize),
            productsOffered: processNullableString(values.productsOffered),
            serviceArea: processNullableString(values.serviceArea),
          },
        },
      });

      if (questionnaireResponse.data?.saveCustomerQuestionnaire.customerQuestionnaire.id) {
        await customerQuestionnaireRefetch();
      }

      if (
        response.data?.updateCustomer.customer?.id &&
        questionnaireResponse.data?.saveCustomerQuestionnaire.customerQuestionnaire.id
      ) {
        toast({ title: "Client Updated", description: "Your updates were saved.", status: "success" });
      }
    } catch (e: any) {
      handleFormGraphQLError(e, "Unable to Save Updates", toast, formikHelpers.setErrors);
    }
  }

  const detailsFormik = useFormik<DetailsFormValues>({
    initialValues: {
      categoryId: customer?.categoryId ?? null,
      name: customer?.name ?? "",
      website: customer?.website ?? "",
      businessDescription: customerQuestionnaire?.businessDescription ?? "",
      customerMarketSize: customerQuestionnaire?.customerMarketSize ?? "",
      productsOffered: customerQuestionnaire?.productsOffered ?? "",
      serviceArea: customerQuestionnaire?.serviceArea ?? "",
    },
    enableReinitialize: true,
    validationSchema: detailsSchema,
    onSubmit,
    validateOnMount: true,
  });

  const length = Object.entries(detailsFormik.values).length;
  const validCount = length - Object.entries(detailsFormik.errors).length;

  return {
    isOverviewLoading: customerAccountQueryResult.loading || isQuestionnaireLoading,
    customer,
    detailsFormik,
    customerAccountLength: length,
    customerAccountValidCount: validCount,
    refetch: customerAccountQueryResult.refetch,
  };
}
