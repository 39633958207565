import { useContext } from "react";
import { faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Alert, Box, Button } from "@chakra-ui/react";
import { CustomersKeywordSort } from "../../generated/graphql";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import AppIcon from "../../components/app-icon";
import { ChangeIndicatorAbsoluteValue } from "../../components/change-indicator";
import { Sort, SortIndicator } from "../../components/sort-indicator";
import Chart from "./keyword-ranking-chart";
import { KeywordRankingContext } from "./keyword-ranking-context";

function KeywordsTable() {
  const {
    keywordQueryResult,
    onKeywordSelect,
    keyword: selectedKeywordId,
    onSort,
    sort,
  } = useContext(KeywordRankingContext);

  const { loading: isLoading = false, error, data } = keywordQueryResult ?? {};
  const keywords = data?.customerKeywords.nodes ?? [];

  if (error) {
    return (
      <Alert status="error">
        <AppIcon icon={faExclamationTriangle} wideRightMargin />
        An error occurred while retrieving the Keywords data.
      </Alert>
    );
  }

  const handleSelect = (keywordId: number) => {
    onKeywordSelect?.(keywordId);
  };

  function onKeywordSort() {
    onSort?.(sort !== CustomersKeywordSort.NameAsc ? CustomersKeywordSort.NameAsc : CustomersKeywordSort.NameDesc);
  }

  function onLatestRankSort() {
    onSort?.(
      sort !== CustomersKeywordSort.LatestRankAsc
        ? CustomersKeywordSort.LatestRankAsc
        : CustomersKeywordSort.LatestRankDesc
    );
  }

  function onTypeSort() {
    onSort?.(
      sort !== CustomersKeywordSort.IsPrimaryAsc
        ? CustomersKeywordSort.IsPrimaryAsc
        : CustomersKeywordSort.IsPrimaryDesc
    );
  }

  return (
    <Table isLoading={isLoading} isEmpty={keywords.length === 0}>
      <TableHead>
        <TableRow key="header">
          <TableHeaderCell>
            <Box onClick={onTypeSort} cursor="pointer">
              Type
              <SortIndicator
                sort={
                  sort === CustomersKeywordSort.IsPrimaryAsc
                    ? Sort.ASC
                    : sort === CustomersKeywordSort.IsPrimaryDesc
                    ? Sort.DESC
                    : undefined
                }
              />
            </Box>
          </TableHeaderCell>
          <TableHeaderCell>
            <Box onClick={onKeywordSort} cursor="pointer">
              Keyword
              <SortIndicator
                sort={
                  sort === CustomersKeywordSort.NameAsc
                    ? Sort.ASC
                    : sort === CustomersKeywordSort.NameDesc
                    ? Sort.DESC
                    : undefined
                }
              />
            </Box>
          </TableHeaderCell>
          <TableHeaderCell>Location</TableHeaderCell>
          <TableHeaderCell>Country</TableHeaderCell>
          <TableHeaderCell>Start Rank</TableHeaderCell>
          <TableHeaderCell>
            <Box onClick={onLatestRankSort} cursor="pointer">
              Latest Rank
              <SortIndicator
                sort={
                  sort === CustomersKeywordSort.LatestRankAsc
                    ? Sort.ASC
                    : sort === CustomersKeywordSort.LatestRankDesc
                    ? Sort.DESC
                    : undefined
                }
              />
            </Box>
          </TableHeaderCell>
          <TableHeaderCell>Start Page</TableHeaderCell>
          <TableHeaderCell>Latest Page</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {keywords.map((keyword) => {
          const isSelected = selectedKeywordId === keyword.id;
          let startPage: number | null = null;
          let latestPage: number | null = null;
          let latestRankChange: number | null = null;
          let latestPageChange: number | null = null;
          if (keyword.startRank) {
            startPage = Math.floor((keyword.startRank.rank - 1) / 10 + 1);
            if (keyword.latestRank) {
              latestPage = Math.floor((keyword.latestRank.rank - 1) / 10 + 1);
              // As rank is in reverse order 1 - high and 100 - low
              // We have calculated reverse change start - current not current - start
              latestRankChange = keyword.startRank.rank - keyword.latestRank.rank;
              latestPageChange = startPage - latestPage;
            }
          }
          return (
            <>
              <TableRow key={keyword.id}>
                <TableCell>{keyword.isPrimary ? "Primary" : "Halo"}</TableCell>
                <TableCell>{keyword.name}</TableCell>
                <TableCell>{keyword.location ?? "-"}</TableCell>
                <TableCell>{keyword.country ?? "-"}</TableCell>
                <TableCell>{keyword.startRank?.rank ?? "-"}</TableCell>
                <TableCell>
                  <ChangeIndicatorAbsoluteValue
                    changeAmount={latestRankChange}
                    absoluteValue={keyword.latestRank?.rank}
                    hideDownValue={true}
                  />
                </TableCell>
                <TableCell>{startPage ?? "-"}</TableCell>
                <TableCell>
                  <ChangeIndicatorAbsoluteValue
                    changeAmount={latestPageChange}
                    absoluteValue={latestPage}
                    hideDownValue={true}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    size="sm"
                    onClick={() => handleSelect(keyword.id)}
                    isDisabled={isSelected}
                    variant="link"
                    colorScheme="blue"
                  >
                    {isSelected && <AppIcon icon={faCheck} standardRightMargin />}
                    {isSelected ? "Selected" : "View"}
                  </Button>
                </TableCell>
              </TableRow>
              {selectedKeywordId === keyword.id && (
                <TableRow>
                  <TableCell colSpan={9}>
                    <Chart />
                  </TableCell>
                </TableRow>
              )}
            </>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default KeywordsTable;
