import { createContext } from "react";
import {
  CustomerKeywordsQueryResult,
  CustomersKeywordSort,
  KeywordMetricsQueryResult,
  KeywordRankingsQueryResult,
  Ui_CustomerKeywordFragment,
} from "../../generated/graphql";

export interface KeywordRankingContextValue {
  keywordQueryResult?: CustomerKeywordsQueryResult;
  keywordRankingsQueryResult?: KeywordRankingsQueryResult;
  keywordMetricsQueryResult?: KeywordMetricsQueryResult;
  selectedKeyword?: Ui_CustomerKeywordFragment;
  pagination?: any;
  sort?: string;
  keyword?: number | null;
  search?: string;
  onSort?: (value: CustomersKeywordSort) => void;
  onKeywordSelect?: (keywordId: number) => void;
}

export const KeywordRankingContext = createContext<KeywordRankingContextValue>({});
