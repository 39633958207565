import { min, max } from "lodash";
import { DateTime } from "luxon";
import { useWorkSummaryQuery, WorkSummary_OrderFragment, WorkSummary_AmTaskFragment } from "../../generated/graphql";
import { useContext, useState } from "react";
import { isBetween } from "../../util/datetime-helpers";
import { AuthContext } from "../../core/auth-manager";

interface WorkSummaryMonth {
  name: string;
  isCurrent: boolean;
  isPast: boolean;
  orders: WorkSummary_OrderFragment[];
  amTasks: WorkSummary_AmTaskFragment[];
}

export function useWorkSummary() {
  const authContext = useContext(AuthContext);
  const workSummaryQueryResult = useWorkSummaryQuery({
    fetchPolicy: "network-only",
  });
  const [showHistory, setShowHistory] = useState(false);

  function toggleShowHistory() {
    setShowHistory(!showHistory);
  }

  const workSummaryMonths: WorkSummaryMonth[] = [];

  const orders = workSummaryQueryResult.data?.orders.nodes ?? [];
  const amTasks = workSummaryQueryResult.data?.amTasks.nodes ?? [];
  if (orders.length > 0 || amTasks.length > 0) {
    const dates = [...orders.map((x) => x.startDate), ...amTasks.map((x) => x.completedAt)];
    const minOrderStartDate = min(dates) as Date;
    const maxOrderStartDate = max(dates) as Date;
    let currentMonth = DateTime.fromJSDate(minOrderStartDate).startOf("month").toJSDate();

    // TODO: correct logic for while loop
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (!showHistory && currentMonth < DateTime.now().minus({ months: 3 }).toJSDate()) {
        currentMonth = DateTime.fromJSDate(currentMonth).plus({ months: 1 }).toJSDate();
        continue;
      }
      const endOfTheMonth = DateTime.fromJSDate(currentMonth).endOf("month").toJSDate();
      workSummaryMonths.push({
        isCurrent: isBetween(new Date(), currentMonth, endOfTheMonth),
        isPast: currentMonth < DateTime.now().startOf("month").toJSDate(),
        name: DateTime.fromJSDate(currentMonth).toFormat("MMMM yyyy"),
        orders: orders.filter((x) => isBetween(x.startDate, currentMonth, endOfTheMonth)),
        amTasks: amTasks.filter((x) => isBetween(x.completedAt, currentMonth, endOfTheMonth)),
      });
      currentMonth = DateTime.fromJSDate(currentMonth).plus({ months: 1 }).toJSDate();
      if (currentMonth > maxOrderStartDate) {
        break;
      }
    }
  }

  function onDownloadWorkSummaryCSV() {
    window.open(`${process.env.REACT_APP_API_BASE}/files/work-summary/${authContext.customer?.id ?? 0}`, "_blank");
  }

  return {
    loading: workSummaryQueryResult.loading,
    workSummaryMonths,
    showHistory,
    toggleShowHistory,
    onDownloadWorkSummaryCSV,
  };
}
