import { Box } from "@chakra-ui/react";

interface Props {
  children: React.ReactNode;
}

export function TableRow(props: Props) {
  const { children } = props;

  return (
    <Box as="tr" display="table-row" outline="0" verticalAlign="middle">
      {children}
    </Box>
  );
}
