import { jsx } from "slate-hyperscript";
import { Node } from "slate";
import { MESSAGE_CONTENT_ELEMENTS } from "./message-content-elements";

function deserializeElement(el: ChildNode): Node {
  if (el.nodeType === 3) {
    const text = el.textContent;
    return { text: text ?? "" };
  } else if (el.nodeType !== 1) {
    return {
      text: "",
    };
  }

  const children = el.childNodes.length > 0 ? Array.from(el.childNodes).map(deserializeElement) : { text: "" };

  switch (el.nodeName) {
    case "H2":
      return jsx("element", { type: MESSAGE_CONTENT_ELEMENTS.HEADING_2 }, children);
    case "H3":
      return jsx("element", { type: MESSAGE_CONTENT_ELEMENTS.HEADING_3 }, children);
    case "H4":
      return jsx("element", { type: MESSAGE_CONTENT_ELEMENTS.HEADING_4 }, children);
    case "H5":
      return jsx("element", { type: MESSAGE_CONTENT_ELEMENTS.HEADING_5 }, children);
    case "H6":
      return jsx("element", { type: MESSAGE_CONTENT_ELEMENTS.HEADING_6 }, children);
    case "UL":
      return jsx("element", { type: MESSAGE_CONTENT_ELEMENTS.BULLETED_LIST }, children);
    case "OL":
      return jsx("element", { type: MESSAGE_CONTENT_ELEMENTS.NUMBERED_LIST }, children);
    case "LI":
      return jsx("element", { type: MESSAGE_CONTENT_ELEMENTS.LIST_ITEM }, children);
    case "BLOCKQUOTE":
      return jsx("element", { type: MESSAGE_CONTENT_ELEMENTS.BLOCK_QUOTE }, children);
    case "A":
      return jsx(
        "element",
        {
          type: MESSAGE_CONTENT_ELEMENTS.LINK,
          url: (el as HTMLAnchorElement).href,
        },
        children
      );
    case "STRONG":
      return jsx("element", { type: MESSAGE_CONTENT_ELEMENTS.STRONG }, children);
    case "P":
    default:
      return jsx("element", { type: MESSAGE_CONTENT_ELEMENTS.PARAGRAPH }, children);
  }
}

function deserializeRoot(el: HTMLElement): Node[] {
  if (el.nodeName !== "BODY") {
    return [];
  }

  const children = Array.from(el.childNodes).map(deserializeElement);

  return jsx("fragment", {}, children);
}

export function deserializeMessageHtml(html: string): Node[] {
  const document = new DOMParser().parseFromString(html, "text/html");
  const result = deserializeRoot(document.body); //.filter((x: any) => x.text !== "");
  return result;
}
