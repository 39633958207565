import { ButtonProps, Button } from "@chakra-ui/react";
import { AppColors } from "../../core/custom-theme";
import Color from "color";

export function PrimaryButton(props: ButtonProps) {
  return (
    <Button
      variant="solid"
      bg={AppColors.primary.main}
      color="white"
      _hover={{
        bg: Color(AppColors.primary.main).darken(0.2).hex(),
      }}
      _active={{
        bg: Color(AppColors.primary.main).darken(0.4).hex(),
      }}
      fontSize="13px"
      paddingX="30px"
      paddingY="10px"
      {...props}
    />
  );
}
