import { Link as RouterLink } from "react-router-dom";
import { AppColors } from "../core/custom-theme";
import { Link, LinkProps } from "@chakra-ui/react";

interface Props extends LinkProps {
  to: string;
  children: React.ReactNode;
  isExternal?: boolean;
}

/* AppLink is a wrapper adding custom styling to React Routers Link component */
export function AppLink(props: Props) {
  const { children, ...rest } = props;
  return (
    <Link
      as={rest.isExternal ? Link : (RouterLink as any)}
      textDecor="none"
      _hover={{ textDecor: "none" }}
      color={AppColors.primary.main}
      href={rest.isExternal ? rest.to : undefined}
      {...rest}
    >
      {children}
    </Link>
  );
}

export default AppLink;
