import { useContext } from "react";
import { AuthContext } from "../../../core/auth-manager";
import AppIcon from "../../app-icon";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Box, Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import AppLink from "../../app-link";
import { useCustomersQuery } from "../../../generated/graphql";

export function MainNavProfile() {
  const authContext = useContext(AuthContext);
  const { viewer, logout } = authContext;
  const queryResult = useCustomersQuery({
    variables: {
      offset: 0,
      limit: 2,
    },
    fetchPolicy: "network-only",
  });
  const hasMultipleCustomers = (queryResult.data?.customers.totalCount ?? 0) > 1;

  return (
    <Box paddingRight={2} textAlign="right" fontSize="14px">
      <Box display="flex" justifyContent="flex-end">
        <Box marginRight={2}>
          <span style={{ display: "block" }}>{viewer?.user?.fullName ?? "Not Logged In"}</span>
          <span style={{ display: "block", fontSize: ".75rem" }}>{authContext.customer?.name ?? ""}</span>
        </Box>
        <Menu autoSelect={false}>
          <Button
            variant="ghost"
            as={MenuButton}
            color="white"
            backgroundColor="#333333"
            _hover={{ backgroundColor: "#333333" }}
            _active={{ backgroundColor: "#333333" }}
          >
            <AppIcon icon={faEllipsisV} />
          </Button>
          <MenuList color="black">
            <AppLink to="/profile" color="black" _hover={{ textDecor: "none" }}>
              <MenuItem color="black">Profile</MenuItem>
            </AppLink>
            <AppLink to="/account" color="black" _hover={{ textDecor: "none" }}>
              <MenuItem color="black">Account Setup</MenuItem>
            </AppLink>
            {hasMultipleCustomers && (
              <AppLink to="/select-client" color="black" _hover={{ textDecor: "none" }}>
                <MenuItem color="black">Switch Client</MenuItem>
              </AppLink>
            )}
            <MenuItem
              color="black"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
}
