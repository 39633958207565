import AppLink from "../../app-link";
import { LinkProps } from "@chakra-ui/react";
import { AppColors } from "../../../core/custom-theme";

interface Props extends LinkProps {
  name: string;
  to: string;
  isActive: boolean;
}

export function MainNavSmallLink(props: Props) {
  const { isActive, name, ...rest } = props;
  return (
    <AppLink
      {...rest}
      color={isActive ? AppColors.primary.main : "black"}
      textDecoration="none"
      fontSize="16px"
      transition="200ms ease-in"
      _focus={{
        color: isActive ? AppColors.primary.main : "black",
      }}
      _hover={{
        color: isActive ? AppColors.primary.main : AppColors.primary.main,
        cursor: "pointer",
      }}
    >
      {name}
    </AppLink>
  );
}
