import { MainNavFullAnonymous } from "./main-nav-full";
import { MainNavSmallAnonymous } from "./main-nav-small";
import { useMediaQuery } from "../../util/use-media-query";

interface Props {
  hasSecondaryNav?: boolean;
  partnerName: string;
  partnerLogoUrl?: string;
}

export function MainNavAnonymous(props: Props) {
  const shouldUseFullNav = useMediaQuery("(min-width: 1565px)");
  if (shouldUseFullNav) {
    return <MainNavFullAnonymous {...props} />;
  }
  return <MainNavSmallAnonymous {...props} />;
}
