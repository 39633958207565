import {
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { Helmet } from "react-helmet";
import { PrimaryButton, PrimaryLinkButton } from "../../components/buttons";
import NoDataDetailsCard from "../../components/no-data-details-card";
import customTheme, { AppColors } from "../../core/custom-theme";
import { useSelectCustomer } from "./select-customer.hook";

export function SelectCustomer() {
  const { customers, error, handleSearchChange, handleSelectCustomer, loading, search, handleLogout } =
    useSelectCustomer();

  if (error) {
    return <NoDataDetailsCard isError error={error} />;
  }

  return (
    <Flex justifyContent="center" height="100vh" padding={{ base: 0, xl: 24 }}>
      <Helmet>
        <title>Select a Client</title>
      </Helmet>
      <Box p={5} width={{ base: "95%", md: "600px" }}>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="lg">Select a Client</Heading>
          <Box>
            <PrimaryLinkButton onClick={handleLogout}>Logout</PrimaryLinkButton>
          </Box>
        </Flex>
        <InputGroup size="md" mt={4}>
          <Input pr="3rem" placeholder="Search Clients" autoFocus onChange={handleSearchChange} value={search} />
          <InputRightElement width="3rem">
            <SearchIcon />
          </InputRightElement>
        </InputGroup>
        <Box shadow="md" borderWidth="1px" minWidth="400px" mt={4} borderRadius={4}>
          {loading && <Spinner margin={4} />}
          {!loading && (
            <List maxH="50vh" overflow="auto">
              {customers.length === 0 && (
                <ListItem px={4} py={1}>
                  <Stack spacing={2}>
                    <Text>No clients found.</Text>
                  </Stack>
                </ListItem>
              )}
              {customers.map((customer) => (
                <ListItem key={customer.id} px={4} py={1}>
                  <Box display="flex" flexWrap="wrap" flexGrow={1}>
                    <Box flexGrow={0} width="60%" maxW="60%">
                      <Box mt="1" as="h4" lineHeight="tight" fontWeight="bold" isTruncated>
                        {customer.name}
                      </Box>
                      <Box color={customTheme.colors.gray["400"]}>{customer.partner.name}</Box>
                    </Box>
                    <Box
                      flexGrow={0}
                      width="40%"
                      maxW="40%"
                      justifyContent="flex-end"
                      display="flex"
                      alignItems="center"
                    >
                      <PrimaryButton
                        variant="solid"
                        textTransform="uppercase"
                        bg={AppColors.primary.main}
                        color="white"
                        _hover={{ bg: AppColors.secondary.main }}
                        onClick={() => {
                          handleSelectCustomer(customer.id);
                        }}
                      >
                        Select
                      </PrimaryButton>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>
    </Flex>
  );
}
