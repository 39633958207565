import { extendTheme } from "@chakra-ui/react";

// Let's say you want to add custom colors
const customTheme = extendTheme({
  fontSizes: {
    xs: ".625rem", // ~10px
    sm: ".75rem", // ~12px
    md: ".875rem", // ~14px
    lg: "1.125rem", // ~18px
    xl: "1.5rem", // ~24px
    "2xl": "2rem", // ~32px
    "3xl": "3rem", // ~48px
  },
  fonts: {
    body: `"Lato",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
    heading: `"Lato",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
    mono: `"Lato",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
  },
});

export const AppColors = {
  primary: {
    main: "#2F80ED",
    light: "#5A9CDB",
    contrastText: "#fff",
  },
  secondary: {
    main: "#000050",
    light: "#00008A",
    contrastText: "#fff",
  },
  red: "#EB5757",
  green: "#008000",
  linkBlue: "#2F80ED",
  textNormal: "#333333",
  textGray: "#BCBCBC",
  borderColor: "#BDBDBD",
  textDarkGray: "#828282",
  textDarkGreen: "#38A169",
  body: "#F5F6F8",
  warning: "#EB5757",
  error: "#EB5757",
  white: "#FFFFFF",
  blue: "#2F80ED",
  // From GitHub Issue: https://github.com/chakra-ui/chakra-ui/issues/2481#issuecomment-785663220
  components: {
    Popover: {
      baseStyle: {
        popper: {
          width: "fit-content",
          maxWidth: "fit-content",
        },
      },
    },
  },
};

export default customTheme;
