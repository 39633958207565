import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_COOKIE_NAME } from "../../constants/constants";
import { AuthContext } from "../../core/auth-manager";
import { useCustomersQuery } from "../../generated/graphql";
import { useDebounce } from "../../util/use-debounce";

const LIMIT = 50;

export function useSelectCustomer() {
  const authContext = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 300);
  const navigate = useNavigate();
  const queryResult = useCustomersQuery({
    variables: {
      offset: 0,
      limit: LIMIT,
      search: debouncedSearchTerm,
    },
    fetchPolicy: "network-only",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customers = queryResult.data?.customers.nodes ?? [];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleSelectCustomer(customerId: number) {
    const cookieDomain = window.location.host.startsWith("localhost") ? "localhost" : ".reportingsmarts.com";
    document.cookie = `${CUSTOMER_COOKIE_NAME}=${customerId.toString()};domain=${cookieDomain};path=/`;
    await authContext.refresh();
    navigate("/");
  }

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  useEffect(() => {
    if (debouncedSearchTerm === "" && customers.length === 1 && !queryResult.loading) {
      handleSelectCustomer(customers[0].id);
    }
  }, [debouncedSearchTerm, customers, queryResult.loading, handleSelectCustomer]);

  return {
    loading: queryResult.loading,
    error: queryResult.error,
    customers,
    search,
    handleSelectCustomer,
    handleSearchChange,
    handleLogout: authContext.logout,
  };
}
