import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Box,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { Ui_ProposedOrderTopicFragment, RejectedTopic } from "../../../../generated/graphql";
import { MMM_D_YYYY } from "../../../../constants/constants";
import { Textarea } from "@chakra-ui/react";
import ErrorCard from "../../../../components/error-card";
import { PrimaryButton, CancelButton } from "../../../../components/buttons";

interface Props {
  isOpen: boolean;
  orderTopic: Ui_ProposedOrderTopicFragment | null;
  onSubmit: (rejectedTopic: RejectedTopic) => void;
  onClose: () => void;
}

function RejectionTopicDrawer(props: Props) {
  const { isOpen, orderTopic, onClose } = props;

  const [rejectionComment, setRejectionComment] = useState(orderTopic?.rejectionComment ?? "");

  useEffect(() => {
    setRejectionComment(orderTopic?.rejectionComment ?? "");
  }, [orderTopic]);

  function onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setRejectionComment(event.target.value);
  }

  function onSubmit() {
    props.onSubmit({
      rejectionComment,
      proposedOrderTopicId: orderTopic?.id ?? 0,
    });
  }

  if (!orderTopic) {
    if (isOpen) {
      return <ErrorCard />;
    }
    return null;
  }

  const startDate = DateTime.fromJSDate(orderTopic.order.startDate).toUTC().toFormat(MMM_D_YYYY);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md" isFullHeight>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Rejection Details</DrawerHeader>
        <DrawerBody>
          <Stack spacing="24px">
            <Box>
              <FormLabel htmlFor="work-type">Work Type</FormLabel>
              <Input id="work-type" isDisabled={true} value={orderTopic.order.workType} />
            </Box>
            <Box>
              <FormLabel htmlFor="star-date">Start Date</FormLabel>
              <Input id="start-date" isDisabled={true} value={startDate} />
            </Box>
            <Box>
              <FormLabel htmlFor="keyword">Keyword</FormLabel>
              <Input id="keyword" isDisabled={true} value={orderTopic.customerKeyword.displayName} />
            </Box>
            <Box>
              <FormLabel htmlFor="topic">Topic</FormLabel>
              <Input id="topic" isDisabled={true} value={orderTopic.topic} />
            </Box>
            <Box>
              <FormLabel htmlFor="rejection-comments">Rejection Comments</FormLabel>
              <Textarea
                id="rejection-comments"
                value={rejectionComment}
                onChange={onChange}
                resize="none"
                placeholder="Please explain why this keyword/topic isn’t acceptable."
              />
            </Box>
          </Stack>
        </DrawerBody>
        <DrawerFooter justifyContent="flex-start" borderTopWidth="1px">
          <SimpleGrid gridGap={4} columns={2} width="100%">
            <PrimaryButton onClick={onSubmit} isDisabled={!rejectionComment.trim().length}>
              Save
            </PrimaryButton>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
          </SimpleGrid>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default RejectionTopicDrawer;
