import { DateTime } from "luxon";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { Heading, Box, Flex, Stack, Image, Text } from "@chakra-ui/react";
import { Dashboard_OrderFragment } from "../../generated/graphql";
import { Table, TableBody, TableRow, TableCell } from "../../components/table";
import { OrderStatus } from "../work-summary/order-status";
import AppLink from "../../components/app-link";
import AppIcon from "../../components/app-icon";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { PanelOverlay } from "../../components/panels/overlay";
import RobotSleeping from "../../assets/robot-sleeping.svg";
import { LOADER_TYPE, MMM_D_YYYY_H_MM_A } from "../../constants/constants";
import Loader from "../../components/loader";

interface Props {
  orders: Dashboard_OrderFragment[];
  loading?: boolean;
}

export function DashboardWorkSummary(props: Props) {
  const { orders, loading } = props;

  if (loading) {
    return <Loader type={LOADER_TYPE.table} />;
  }

  return (
    <PanelGroup hasOverlay={orders.length === 0}>
      {orders.length === 0 && (
        <PanelOverlay>
          <Flex justifyContent="center" width="100%">
            <Stack spacing={4} width="60%" textAlign="center">
              <Image src={RobotSleeping} alt="Data Processing" maxH={100} />
              <Text>
                Work Summary will activate with data as soon as keyword research and content strategy are created and
                approved. Stay tuned!
              </Text>
            </Stack>
          </Flex>
        </PanelOverlay>
      )}
      <PanelTop showBottomBorder>
        <Heading size="md">Work Summary</Heading>
      </PanelTop>
      <PanelBottom containsTable minH={250}>
        <Table isLoading={false} isEmpty={false}>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell>{order.workType}</TableCell>
                <TableCell>
                  <Box textAlign="right">
                    <OrderStatus
                      status={order.status}
                      startDate={DateTime.fromJSDate(order.startDate).toFormat(MMM_D_YYYY_H_MM_A)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            {orders.length > 0 && (
              <TableRow key="view-all">
                <TableCell colSpan={2}>
                  <Box textAlign="center">
                    <AppLink to="/work-summary">
                      View all
                      <AppIcon icon={faAngleRight} standardLeftMargin />
                    </AppLink>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </PanelBottom>
    </PanelGroup>
  );
}
