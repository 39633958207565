import { useEffect, useContext, useState } from "react";
import { Loading } from "./loading";
import { useSso_LoginUserMutation, useTrackSsoEventMutation } from "../../generated/graphql";
import { useParams, useNavigate } from "react-router-dom";
import { SsoError } from "./error";
import { AuthContext } from "../../core/auth-manager";
import { USER_EVENTS } from "../../constants/user-events";

export function Sso() {
  const [loginUserMutation] = useSso_LoginUserMutation();
  const [trackUserEvent] = useTrackSsoEventMutation();
  const [showError, setShowError] = useState(false);
  const { code, "*": redirect } = useParams<"code" | "*">();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  useEffect(() => {
    async function login() {
      const loginResponse = await loginUserMutation({
        variables: {
          input: {
            ssoCode: code,
          },
        },
      });

      if (loginResponse.data?.loginUser.isLoggedIn ?? false) {
        await authContext.refresh();
        await trackUserEvent({
          variables: {
            input: {
              eventType: USER_EVENTS.PAGE_VIEWED,
              entityName: redirect ? redirect : "dashboard",
              userId: loginResponse.data?.loginUser.userId ?? 0,
            },
          },
        });
        navigate(redirect ? `/${redirect}` : "/");
      } else {
        authContext.logout();
        setShowError(true);
      }
    }
    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!code) {
    return <SsoError />;
  }

  if (showError) {
    return <SsoError />;
  }

  return <Loading />;
}
