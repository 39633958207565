import { SimpleGrid, Box, Stack } from "@chakra-ui/react";
import {
  useDashboardConversationsQuery,
  useDashboardOrdersQuery,
  useDashboardMetricsQuery,
} from "../../generated/graphql";
import { PageTitle } from "../../components/page-title";
import { StandardLayoutV2, MainNav } from "../../components/layout-v2";
import { Container } from "../../components/layout-v2/container";
import { DashboardMessages } from "./dashboard-messages";
import { DashboardWorkSummary } from "./dashboard-work-summary";
import { ActionItemsBar } from "../../common/action-items-bar";
import { DashboardMeeting } from "./dashboard-meeting";
import DashboardKeywordMetrics from "./dashboard-keyowrd-metics";

export function Dashboard() {
  const dashboardConversationsQueryResult = useDashboardConversationsQuery({
    fetchPolicy: "network-only",
  });

  const dashboardOrdersQueryResult = useDashboardOrdersQuery({
    fetchPolicy: "network-only",
  });

  const dashboardMetricsQueryResult = useDashboardMetricsQuery({
    fetchPolicy: "network-only",
  });

  return (
    <StandardLayoutV2 pageTitle="Dashboard">
      <MainNav />
      <Container centerHorizontally>
        <ActionItemsBar />
        <PageTitle>Dashboard</PageTitle>
        <Box marginBottom={4}>
          <DashboardKeywordMetrics
            loading={dashboardMetricsQueryResult.loading}
            customer={dashboardMetricsQueryResult.data?.customer ?? null}
          />
        </Box>
        <SimpleGrid minChildWidth="400px" spacing={4}>
          <Box>
            <Stack spacing={4}>
              <DashboardWorkSummary
                loading={dashboardOrdersQueryResult.loading}
                orders={dashboardOrdersQueryResult.data?.orders.nodes ?? []}
              />
              <DashboardMeeting />
            </Stack>
          </Box>
          <Box>
            <Stack spacing={4}>
              <DashboardMessages
                loading={dashboardConversationsQueryResult.loading}
                conversations={dashboardConversationsQueryResult.data?.conversations.nodes ?? []}
              />
            </Stack>
          </Box>
        </SimpleGrid>
      </Container>
    </StandardLayoutV2>
  );
}
