import { useState } from "react";
import { BoxProps, Box } from "@chakra-ui/react";
import { PanelContext } from "./context";

interface Props extends BoxProps {
  isCollapsible?: boolean;
  isCollapsedByDefault?: boolean;
  hasOverlay?: boolean;
}

export function PanelGroup(props: Props) {
  const { isCollapsible, isCollapsedByDefault, hasOverlay, ...rest } = props;
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedByDefault ?? false);

  function toggleCollapse() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <PanelContext.Provider value={{ isCollapsed, isCollapsible: isCollapsible ?? false, toggleCollapse }}>
      <Box position={hasOverlay ? "relative" : "inherit"}>
        <Box marginBottom={hasOverlay ? 0 : 4} {...rest} />
      </Box>
    </PanelContext.Provider>
  );
}
