import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useContentReviewTaskWorkspaceQuery,
  useContentReview_SaveReviewTaskContentItemMutation,
  useSubmitContentReviewMutation,
  File,
} from "../../../../generated/graphql";
import { handleFormGraphQLError } from "../../../../util/error-helper";
import { UploadFile } from "../../../../common/upload-file-modal/use-upload-file-modal";

interface Props {
  taskId: number;
}

type ServerFile = Pick<File, "id" | "name" | "url">;

export function useContentReview(props: Props) {
  const { taskId } = props;
  const toast = useToast();
  const navigate = useNavigate();

  const [showReject, setShowReject] = useState(false);
  const [rejectionComments, setRejectionComments] = useState("");
  const [content, setContent] = useState("");
  const [isContentModified, setIsContentModified] = useState(false);
  const [file, setFile] = useState<ServerFile | undefined | null>(undefined);
  const [showThumbnailUpload, setShowThumbnailUpload] = useState(false);

  const { data, loading, error } = useContentReviewTaskWorkspaceQuery({
    variables: {
      taskId,
    },
    fetchPolicy: "network-only",
  });

  const [submitContentReiew, { loading: isSubmitting }] = useSubmitContentReviewMutation();
  const [saveReviewTaskContentItem, { loading: isSubmittingContent }] =
    useContentReview_SaveReviewTaskContentItemMutation();

  const task = data?.task ?? null;

  useEffect(() => {
    if (content === "" && !!task?.contentItem?.content) {
      setContent(task.contentItem.content);
    }
    setFile(task?.contentItem?.thumbnailFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  async function onSubmit() {
    if (!task?.contentItem) {
      return;
    }

    if (isContentModified) {
      try {
        const response = await saveReviewTaskContentItem({
          variables: {
            input: {
              taskId,
              content,
              thumbnailS3FileId: file?.id ?? null,
              title: task.contentItem.title,
            },
          },
        });
        if (!response.data?.saveReviewTaskContentItem.ok) {
          throw new Error(
            response.data?.saveReviewTaskContentItem.error?.message ??
              "Unable to save content changes. Please try again."
          );
        }
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to Save Content Changes", toast);
        return;
      }
    }

    const isApproved = isContentModified || !showReject;

    try {
      const response = await submitContentReiew({
        variables: {
          input: {
            isApproved,
            taskId,
            rejectionComments,
          },
        },
      });

      if (response?.data?.submitContentReview?.task?.id) {
        toast({
          title: "Completed",
          description: "The Content Review was submitted successfully.",
          status: "success",
        });
      }

      navigate("/");
    } catch (e: any) {
      handleFormGraphQLError(e, "Unable to Submit Review", toast);
      return;
    }
  }

  function onReject() {
    setShowReject(true);
  }

  function onCancel() {
    setShowReject(false);
    setRejectionComments("");
  }

  function onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setRejectionComments(event.target.value);
  }

  function handleContentChange(value: string) {
    const isDifferentContent = value !== (task?.contentItem?.content ?? "");
    setIsContentModified(isDifferentContent);
    if (isDifferentContent) {
      setShowReject(false);
      setRejectionComments("");
    }
    setContent(value);
  }

  function handleCancelModifications() {
    setContent(task?.contentItem?.content ?? "");
    setFile(task?.contentItem?.thumbnailFile);
    setIsContentModified(false);
  }

  function onThumbnailUpload() {
    setShowThumbnailUpload(true);
  }

  function onThumbnailUploadCancel() {
    setShowThumbnailUpload(false);
  }

  async function onThumbnailUploaded(fileId: string | null, file?: UploadFile) {
    // Do we want to set show reject to false and rejection comments to ""
    if (fileId && file && task?.contentItem?.thumbnailFile?.id !== fileId) {
      setFile({ id: fileId, name: file.name, url: file.url });
      setIsContentModified(true);
      setShowReject(false);
      setRejectionComments("");
    }
    setShowThumbnailUpload(false);
  }

  function onThumbnailRemove() {
    setFile(null);
    setIsContentModified(true);
    setShowReject(false);
    setRejectionComments("");
  }

  return {
    loading,
    error,
    task,
    isSubmitting: isSubmitting || isSubmittingContent,
    onSubmit,
    showReject,
    rejectionComments,
    onReject,
    onCancel,
    onChange,
    isContentModified,
    handleContentChange,
    handleCancelModifications,
    content,
    file,
    showThumbnailUpload,
    onThumbnailUploadCancel,
    onThumbnailUpload,
    onThumbnailUploaded,
    onThumbnailRemove,
  };
}
