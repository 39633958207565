import { useContext } from "react";
import { Button } from "@chakra-ui/react";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Navigate } from "react-router-dom";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";

import { AppColors } from "../../core/custom-theme";
import { useLoginUserMutation } from "../../generated/graphql";
import { AuthContext } from "../../core/auth-manager";
import AppIcon from "../../components/app-icon";
import LoginContainer from "../../components/login-container";

export default function SignIn() {
  const authContext = useContext(AuthContext);
  const [loginUserMutation] = useLoginUserMutation();
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  if (!googleClientId) {
    throw new Error("Unable to setup Login with Google");
  }

  async function onGoogleLoginSuccess(response: TokenResponse) {
    if (!response.access_token) {
      return null;
    }

    await loginUserMutation({
      variables: {
        input: {
          googleAccessToken: response.access_token,
        },
      },
    });
    await authContext.refresh();
  }

  function onGoogleLoginFailure(e: any) {
    console.error("Error", { e });
  }

  const login = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
    onError: onGoogleLoginFailure,
  });

  function onGoogleLoginClick() {
    login();
  }

  if (authContext.isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <LoginContainer>
      <Button
        onClick={onGoogleLoginClick}
        bg={AppColors.primary.main}
        color="white"
        _hover={{ bg: AppColors.secondary.main }}
        variant="solid"
        textTransform="uppercase"
        width="100%"
      >
        <AppIcon icon={faGoogle} wideRightMargin /> Login with Google
      </Button>
    </LoginContainer>
  );
}
