import React, { Component } from "react";
import {
  AuthManagerQueryResult,
  LogoutUserMutationFn,
  AuthManager_ViewerFragment,
  AuthManager_CustomerFragment,
} from "../../generated/graphql";
import { ConnectivityContext } from "../connectivity-monitor";
import { WithRouterProps, withRouter } from "./with-router";

export interface AuthContextValue {
  isLoading: boolean;
  isLoggedIn: boolean;
  refresh: () => Promise<any>;
  logout: () => Promise<any>;
  viewer: AuthManager_ViewerFragment | null;
  customer: AuthManager_CustomerFragment | null;
}

interface Props extends WithRouterProps {
  queryResult: AuthManagerQueryResult;
  children: React.ReactNode;
  logoutUserMutation: LogoutUserMutationFn;
}

export const AuthContext = React.createContext<AuthContextValue>({
  isLoading: true,
  isLoggedIn: false,
  refresh: async () => {
    // Do nothing
  },
  logout: async () => {
    // Do nothing
  },
  viewer: null,
  customer: null,
});

class AuthManager extends Component<Props> {
  render() {
    const { queryResult, logoutUserMutation } = this.props;
    const contextValue: AuthContextValue = {
      isLoading: queryResult.loading,
      isLoggedIn: false,
      refresh: queryResult.refetch,
      logout: async () => {
        await logoutUserMutation();
        await queryResult.refetch();
      },
      viewer: null,
      customer: null,
    };

    if (!queryResult.loading) {
      const { data, error } = queryResult;
      const viewer = data?.viewer ?? null;
      if (data) {
        contextValue.isLoggedIn = !error && !!viewer?.user;
        contextValue.viewer = viewer;
        contextValue.customer = data.customer ?? null;
      }
    }
    return (
      <AuthContext.Provider value={contextValue}>
        <ConnectivityContext.Consumer>
          {(connectivityContext) => this.renderContent(contextValue, connectivityContext.isConnected)}
        </ConnectivityContext.Consumer>
      </AuthContext.Provider>
    );
  }

  logout = () => {
    // TODO: implement
  };

  renderContent(contextValue: AuthContextValue, isConnected: boolean) {
    const { children } = this.props;
    if (!contextValue) {
      return null;
    }
    if (!isConnected) {
      // If API connectivity goes down, prevent redirects for an improved user experience.
      return null;
    }
    return children;
  }
}

export default withRouter(AuthManager);
