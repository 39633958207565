import { BrowserRouter as Router, Route, Routes as RouterRoutes, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { QueryParamProvider } from "use-query-params";
import { lazy, Suspense } from "react";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { CustomApolloProvider } from "./core/apollo-provider";
import { ConnectivityMonitor } from "./core/connectivity-monitor";
import AuthManager from "./core/auth-manager";
import customTheme from "./core/custom-theme";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import AccountRoute from "./routes/account";
import ConversationRoute from "./routes/conversation";
import KeywordRankingsRoute from "./routes/keyword-rankings";
import { LoginContainer } from "./routes/login/login-container";
import MessagesRoute from "./routes/messages";
import NotFoundRouteHandler from "./routes/not-found";
import { SelectCustomerRoute } from "./routes/select-client";
import { Sso } from "./routes/sso";
import TaskRoute from "./routes/task";
import { WorkSummaryRoute } from "./routes/work-summary";
import { RequireUser } from "./components/require-user";
import { DashboardRoute } from "./routes/dashboard";
import { DeliverableRoute } from "./routes/deliverable";
import { DeliverableFileRoute } from "./routes/deliverable-file";
import AnalyticsRoute from "./routes/analytics";
import { ProfileRoute } from "./routes/profile";
import Loader from "./components/loader";

const LocationsReport = lazy(() => import("./routes/locations-report"));
const DeliverableGbp = lazy(() => import("./routes/deliverable-gbp"));

Sentry.init({
  dsn: "https://2990d1e1920d4227b07c8820dd05bd61@o949542.ingest.sentry.io/5899854",
  integrations: [new Integrations.BrowserTracing()],

  release: `rcm-ui-customer@${process.env.REACT_APP_VERSION}`,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_ENVIRONMENT_PREFIX !== "local",
  environment: process.env.REACT_APP_ENVIRONMENT_PREFIX,
});

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <ChakraProvider theme={customTheme}>
        <CSSReset />
        <ConnectivityMonitor>
          <CustomApolloProvider>
            <Router>
              <AuthManager>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                  <Suspense fallback={<Loader />}>
                    <RouterRoutes>
                      <Route path="/login" element={<LoginContainer />} />
                      <Route path="/sso/:code/*" element={<Sso />} />
                      <Route
                        path="/"
                        element={
                          <RequireUser requireCustomer>
                            <Navigate to="/dashboard" />
                          </RequireUser>
                        }
                      />
                      <Route
                        path="/dashboard"
                        element={
                          <RequireUser requireCustomer>
                            <DashboardRoute />
                          </RequireUser>
                        }
                      />
                      <Route
                        path="/select-client"
                        element={
                          <RequireUser>
                            <SelectCustomerRoute />
                          </RequireUser>
                        }
                      />
                      <Route
                        path="/keyword-rankings"
                        element={
                          <RequireUser requireCustomer>
                            <KeywordRankingsRoute />
                          </RequireUser>
                        }
                      />
                      <Route
                        path="/work-summary"
                        element={
                          <RequireUser requireCustomer>
                            <WorkSummaryRoute />
                          </RequireUser>
                        }
                      />
                      <Route
                        path="/messages"
                        element={
                          <RequireUser requireCustomer>
                            <MessagesRoute />
                          </RequireUser>
                        }
                      />
                      <Route
                        path="/messages/sent"
                        element={
                          <RequireUser requireCustomer>
                            <MessagesRoute />
                          </RequireUser>
                        }
                      />
                      <Route
                        path="/messages/:conversationId"
                        element={
                          <RequireUser requireCustomer>
                            <ConversationRoute />
                          </RequireUser>
                        }
                      />
                      <Route
                        path="/profile"
                        element={
                          <RequireUser>
                            <ProfileRoute />
                          </RequireUser>
                        }
                      />
                      <Route
                        path="/account"
                        element={
                          <RequireUser>
                            <AccountRoute />
                          </RequireUser>
                        }
                      />
                      <Route
                        path="/analytics"
                        element={
                          <RequireUser requireCustomer>
                            <AnalyticsRoute />
                          </RequireUser>
                        }
                      />
                      <Route
                        path="/tasks/:id"
                        element={
                          <RequireUser requireCustomer>
                            <TaskRoute />
                          </RequireUser>
                        }
                      />
                      <Route path="/deliverable/:publicId" element={<DeliverableRoute />} />
                      <Route path="/deliverable-gbp/:publicId" element={<DeliverableGbp />} />
                      <Route path="/deliverable-file/:order/:file" element={<DeliverableFileRoute />} />
                      <Route
                        path="/locations-report"
                        element={
                          <RequireUser requireCustomer>
                            <LocationsReport />
                          </RequireUser>
                        }
                      />
                      <Route path="*" element={<NotFoundRouteHandler />} />
                    </RouterRoutes>
                  </Suspense>
                </QueryParamProvider>
              </AuthManager>
            </Router>
          </CustomApolloProvider>
        </ConnectivityMonitor>
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
