import { useTheme, BoxProps, Box } from "@chakra-ui/react";

interface Props extends BoxProps {
  children: React.ReactNode;
}

export function Card(props: Props) {
  const { children, ...rest } = props;
  const theme = useTheme();
  return (
    <Box
      paddingX={4}
      paddingY={4}
      backgroundColor={theme.colors.white}
      borderRadius={4}
      border={`1px solid ${theme.colors.gray[300]}`}
      {...rest}
    >
      {children}
    </Box>
  );
}
