import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Textarea, Heading, Stack } from "@chakra-ui/react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useConversationQuery, useCreateMessageMutation } from "../../generated/graphql";
import AppIcon from "../../components/app-icon";
import MessageItem from "./message-item";
import { PageTitle } from "../../components/page-title";
import { StandardLayoutV2, MainNav } from "../../components/layout-v2";
import { Container } from "../../components/layout-v2/container";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import AppLink from "../../components/app-link";
import { PrimaryButton, CancelButton } from "../../components/buttons";

function ConversationRoute() {
  const { conversationId } = useParams<"conversationId">();
  const [content, setContent] = useState("");

  const queryResult = useConversationQuery({
    variables: {
      conversationId: conversationId ?? "",
    },
    fetchPolicy: "network-only",
  });

  const { data } = queryResult;

  const [createMessage, { loading }] = useCreateMessageMutation();

  function onContentChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setContent(event.target.value);
  }

  async function onPost() {
    try {
      const response = await createMessage({
        variables: {
          input: {
            conversationId: conversationId ?? "",
            content,
          },
        },
      });

      if (response?.data?.createMessage?.message.id) {
        setContent("");
        queryResult.refetch();
      }
    } catch (e: any) {
      // Error message toast handled by Apollo Provider
    }
  }

  const subject = data?.conversation?.subject ?? "";
  const messages = data?.conversation?.messages.nodes ?? [];

  return (
    <StandardLayoutV2 pageTitle="Messages">
      <MainNav />
      <Container centerHorizontally marginBottom={12}>
        <Box my={4}>
          <AppLink to="/messages">
            <AppIcon icon={faChevronLeft} standardRightMargin />
            Back to Messages
          </AppLink>
        </Box>
        <Box py={2}>
          <PageTitle>{subject}</PageTitle>
        </Box>
        <Box>
          {messages.map((message) => (
            <MessageItem
              isFromClient={message.isFromCustomer}
              key={message.id}
              userName={message.user.fullName}
              createdAt={message.createdAt}
              content={message.content}
            />
          ))}
        </Box>
        <PanelGroup>
          <PanelTop>
            <Heading size="md">Reply</Heading>
          </PanelTop>
          <PanelBottom>
            <Textarea value={content} onChange={onContentChange} resize="none" placeholder="Comments" />
            {content !== "" && (
              <Stack isInline spacing={2} marginTop={2}>
                <PrimaryButton
                  variant="solid"
                  colorScheme="blue"
                  onClick={onPost}
                  isDisabled={!content.trim().length}
                  isLoading={loading}
                >
                  Send
                </PrimaryButton>
                <CancelButton
                  onClick={() => {
                    setContent("");
                  }}
                >
                  Cancel
                </CancelButton>
              </Stack>
            )}
          </PanelBottom>
        </PanelGroup>
      </Container>
    </StandardLayoutV2>
  );
}

export default ConversationRoute;
