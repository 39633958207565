import { useTheme } from "@chakra-ui/react";

interface Props {
  children?: React.ReactNode;
  colSpan?: number;
  width?: string | number;
  hideBottomBorder?: boolean;
}

export function TableCell(props: Props) {
  const { children, colSpan, width, hideBottomBorder } = props;
  const theme = useTheme();

  return (
    <td
      colSpan={colSpan}
      style={{
        display: "table-cell",
        padding: theme.space[4],
        fontSize: "14px",
        textAlign: "left",
        borderBottom: hideBottomBorder ? undefined : `1px solid #E3E8EF`,
        width,
      }}
    >
      {children}
    </td>
  );
}
