import { useState, useContext } from "react";
import { DateTime } from "luxon";
import { Box, Flex, Text } from "@chakra-ui/react";
import { PageTitle } from "../../components/page-title";
import ConversationCreateDialog from "./conversation-create-dialog";
import { AuthContext } from "../../core/auth-manager";
import { StandardLayoutV2, MainNav } from "../../components/layout-v2";
import { Container } from "../../components/layout-v2/container";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { PrimaryButton } from "../../components/buttons";
import { Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell } from "../../components/table";
import { useConversationsQuery } from "../../generated/graphql";
import { MMM_D_YYYY_H_MM_A } from "../../constants/constants";
import AppLink from "../../components/app-link";
import { ActionItemsBar } from "../../common/action-items-bar";

function MessagesRoute() {
  const [newMessage, setNewMessage] = useState(false);
  const authContext = useContext(AuthContext);
  const isAdmin = authContext.viewer?.isAdmin ?? false;

  const queryResult = useConversationsQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: 100,
      offset: 0,
    },
  });

  const conversations = queryResult.data?.conversations.nodes ?? [];

  function onNewMessage() {
    setNewMessage(true);
  }

  function onClose() {
    setNewMessage(false);
  }

  return (
    <StandardLayoutV2 pageTitle="Messages">
      <MainNav />
      <Container centerHorizontally>
        <ActionItemsBar />
        <PageTitle>Messages</PageTitle>
        <PanelGroup>
          <PanelTop>
            <Flex justifyContent="space-between" alignItems="center">
              <Box></Box>
              <Box>
                <PrimaryButton isDisabled={isAdmin} size="sm" onClick={onNewMessage} variant="solid" colorScheme="blue">
                  New Message
                </PrimaryButton>
              </Box>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isLoading={queryResult.loading} isEmpty={conversations.length === 0}>
              <TableHead>
                <TableRow key={1}>
                  <TableHeaderCell>Subject</TableHeaderCell>
                  <TableHeaderCell>Created</TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {conversations.map((conversation) => (
                  <TableRow key={conversation.id}>
                    <TableCell>
                      <AppLink to={`/messages/${conversation.id}`}>
                        <Text isTruncated>{conversation.subject}</Text>
                      </AppLink>
                    </TableCell>
                    <TableCell>{DateTime.fromJSDate(conversation.createdAt).toFormat(MMM_D_YYYY_H_MM_A)}</TableCell>
                    <TableCell>
                      <span />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PanelBottom>
        </PanelGroup>
        <ConversationCreateDialog isOpen={newMessage} onClose={onClose} />
      </Container>
    </StandardLayoutV2>
  );
}

export default MessagesRoute;
