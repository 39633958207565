import { BoxProps, Flex, Box } from "@chakra-ui/react";

export function PanelOverlay(props: BoxProps) {
  return (
    <Flex
      width="100%"
      position="absolute"
      backgroundColor="rgba(255,255,255,0.9)"
      top={0}
      left={0}
      justifyContent="center"
      alignItems="center"
      height="100%"
      border="1px solid #E3E8EF"
      borderRadius={4}
      zIndex={10}
    >
      <Box {...props} />
    </Flex>
  );
}
