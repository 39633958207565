import { Box, Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import AppIcon from "../app-icon";

interface Props {
  children: React.ReactNode;
  standardLeftMargin?: boolean;
  standardRightMargin?: boolean;
  wideLeftMargin?: boolean;
  wideRightMargin?: boolean;
}

export function HelpText(props: Props) {
  const { children, ...rest } = props;
  return (
    <Popover trigger="hover" isLazy>
      <PopoverTrigger>
        <span>
          <AppIcon icon={faQuestionCircle} {...rest} />
        </span>
      </PopoverTrigger>
      <PopoverContent>
        <Box backgroundColor="#333333" color="white" p={2} fontSize="sm">
          {children}
        </Box>
      </PopoverContent>
    </Popover>
  );
}
