import React from "react";
import { Box, Heading, Link, Text } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import { AppColors } from "../../core/custom-theme";
import { deserializeHtml } from "./html-deserializer";
import { RICH_CONTENT_ELEMENTS } from "./rich-content-elements";

interface Props {
  content: string;
}

function renderElement(node: any): React.ReactNode {
  if (node.text) {
    return (node.text as string) ?? "";
  }
  const children = Array.isArray(node.children) ? (node.children as any[]).map(renderElement) : "";

  switch (node.type) {
    case RICH_CONTENT_ELEMENTS.BLOCK_QUOTE:
      return (
        <Box borderLeft={`3px solid ${AppColors.textDarkGray}`} paddingLeft={4} marginBottom={4}>
          {children}
        </Box>
      );
    case RICH_CONTENT_ELEMENTS.BULLETED_LIST:
      return (
        <Box marginBottom={4} marginLeft="1.5rem">
          <ul>{children}</ul>
        </Box>
      );
    case RICH_CONTENT_ELEMENTS.NUMBERED_LIST:
      return (
        <Box marginBottom={4} marginLeft="1.5rem">
          <ol>{children}</ol>
        </Box>
      );
    case RICH_CONTENT_ELEMENTS.HEADING_1:
      return (
        <Heading as="h1" size="xl">
          {children}
        </Heading>
      );
    case RICH_CONTENT_ELEMENTS.HEADING_2:
      return (
        <Heading as="h2" size="lg">
          {children}
        </Heading>
      );
    case RICH_CONTENT_ELEMENTS.HEADING_3:
      return (
        <Heading as="h3" size="md">
          {children}
        </Heading>
      );
    case RICH_CONTENT_ELEMENTS.HEADING_4:
      return (
        <Heading as="h4" size="sm">
          {children}
        </Heading>
      );
    case RICH_CONTENT_ELEMENTS.HEADING_5:
      return (
        <Heading as="h5" size="sm">
          {children}
        </Heading>
      );
    case RICH_CONTENT_ELEMENTS.HEADING_6:
      return (
        <Heading as="h6" size="xs">
          {children}
        </Heading>
      );
    case RICH_CONTENT_ELEMENTS.LIST_ITEM:
      return <li>{children}</li>;
    case RICH_CONTENT_ELEMENTS.LINK:
      return (
        <Link color={AppColors.linkBlue} href={node.url as string}>
          {children}
        </Link>
      );
    case RICH_CONTENT_ELEMENTS.STRONG:
      return <strong>{children}</strong>;
    case RICH_CONTENT_ELEMENTS.SPAN:
      return <span>{children}</span>;
    default:
      return <Text marginBottom={4}>{children}</Text>;
  }
}

function renderMessageContent(content: string): React.ReactNode {
  const deserialized = deserializeHtml(content);
  return <>{deserialized.map(renderElement)}</>;
}

export function RichContent(props: Props) {
  const { content } = props;
  const isHtml = content.trim().startsWith("<") && content.trim().endsWith(">");

  if (isHtml) {
    return <>{renderMessageContent(content)}</>;
  }
  // eslint-disable-next-line react/no-children-prop
  return <ReactMarkdown children={content} />;
}
