import { useToast } from "@chakra-ui/react";

import { Navigate } from "react-router-dom";

export function TaskCompleted() {
  const toast = useToast();

  toast({
    description: "This task has already been completed.",
    status: "success",
    title: "Already Completed",
  });
  return <Navigate to="/" />;
}
