import { Box, Flex, Stack } from "@chakra-ui/react";
import NoDataAvailable from "./no-data-available";
import Loader from "./loader";
import ErrorCard from "./error-card";
import { LOADER_TYPE } from "../constants/constants";
import { StandardLayoutV2 } from "./layout-v2";
import { ApolloError } from "@apollo/client";

interface Props {
  isLoading?: boolean;
  isError?: boolean;
  error?: ApolloError;
}

function NoDataDetailsCard(props: Props) {
  const { isLoading, isError, error } = props;

  return (
    <StandardLayoutV2 pageTitle="Error">
      <Flex direction="row" justify="center" alignItems="center">
        <Flex w="100%" maxW="100%" flexGrow={0} flexBasis="100%" flexWrap="wrap" margin={0}>
          <Stack>
            <Box p={3} shadow="md" borderWidth="1px">
              {isLoading && !isError && <Loader type={LOADER_TYPE.content} />}
              {isError && !isLoading && <ErrorCard error={error} />}
              {!isLoading && !isError && <NoDataAvailable />}
            </Box>
          </Stack>
        </Flex>
      </Flex>
    </StandardLayoutV2>
  );
}

export default NoDataDetailsCard;
