import { useState } from "react";
import { DateTime } from "luxon";
import { Box, Button, Menu, MenuButton, MenuList, MenuItem, Tooltip } from "@chakra-ui/react";
import { faEllipsisV, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { RejectedTopic, Ui_ProposedOrderTopicFragment } from "../../../../generated/graphql";
import { MMM_D_YYYY } from "../../../../constants/constants";
import { Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell } from "../../../../components/table";
import AppIcon from "../../../../components/app-icon";
import RejectionTopicDrawer from "./rejection-topic-drawer";

interface Props {
  proposedOrderTopics: Ui_ProposedOrderTopicFragment[];
  rejectedTopics: RejectedTopic[];
  onRejected: (rejectedTopic: RejectedTopic) => void;
  onCancel: (orderTopicId: number) => void;
}

function ProposedOrderTopicTable(props: Props) {
  const { proposedOrderTopics, onCancel, rejectedTopics } = props;

  const [rejectOrderTopic, setRejectOrderTopic] = useState<Ui_ProposedOrderTopicFragment | null>(null);

  function onReject(orderTopic: Ui_ProposedOrderTopicFragment) {
    const rejectedTopic = rejectedTopics.find((rejectedTopic) => rejectedTopic.proposedOrderTopicId === orderTopic.id);
    if (rejectedTopic) {
      orderTopic = { ...orderTopic, rejectionComment: rejectedTopic.rejectionComment };
    }
    setRejectOrderTopic(orderTopic);
  }

  function onCancelReject(orderTopic: Ui_ProposedOrderTopicFragment) {
    onCancel(orderTopic.id);
  }

  function onClose() {
    setRejectOrderTopic(null);
  }

  function onRejected(rejectedTopic: RejectedTopic) {
    props.onRejected(rejectedTopic);
    setRejectOrderTopic(null);
  }

  const rejectedTopicIds = rejectedTopics.map((rejectedTopic) => rejectedTopic.proposedOrderTopicId);

  return (
    <>
      <RejectionTopicDrawer
        isOpen={!!rejectOrderTopic}
        orderTopic={rejectOrderTopic}
        onSubmit={onRejected}
        onClose={onClose}
      />
      <Table isLoading={false} isEmpty={proposedOrderTopics.length === 0}>
        <TableHead>
          <TableRow key="header">
            <TableHeaderCell>Work Type</TableHeaderCell>
            <TableHeaderCell>Start Date</TableHeaderCell>
            <TableHeaderCell>Keyword</TableHeaderCell>
            <TableHeaderCell>Topic</TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {proposedOrderTopics.map((orderTopic) => {
            const isRejected = rejectedTopicIds.includes(orderTopic.id);
            const startDate = DateTime.fromJSDate(orderTopic.order.startDate).toUTC().toFormat(MMM_D_YYYY);

            return (
              <TableRow key={orderTopic.id}>
                <TableCell>{orderTopic.order.workType}</TableCell>
                <TableCell>{startDate}</TableCell>
                <TableCell>{orderTopic.customerKeyword?.displayName ?? "-"}</TableCell>
                <TableCell>{orderTopic?.topic ?? "-"}</TableCell>
                <TableCell>
                  <Box textAlign="right">
                    {isRejected && (
                      <Tooltip hasArrow label="This item has been rejected." aria-label="Rejection Icon">
                        <span>
                          <AppIcon icon={faExclamationTriangle} color="#FF3636" standardRightMargin />
                        </span>
                      </Tooltip>
                    )}
                    <Menu>
                      <Button as={MenuButton} variant="ghost">
                        <AppIcon icon={faEllipsisV} />
                      </Button>
                      <MenuList>
                        {isRejected ? (
                          <>
                            <MenuItem onClick={onReject.bind(null, orderTopic)}>Edit Rejection</MenuItem>
                            <MenuItem onClick={onCancelReject.bind(null, orderTopic)}>Cancel Rejection</MenuItem>
                          </>
                        ) : (
                          <MenuItem onClick={onReject.bind(null, orderTopic)} color="#FF3636">
                            Reject
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default ProposedOrderTopicTable;
