import { Box, Image, Text } from "@chakra-ui/react";
import AppIcon from "../../app-icon";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

export const MAIN_NAV_HEIGHT = "64px";

interface Props {
  hasSecondaryNav?: boolean;
  partnerName?: string;
  partnerLogoUrl?: string;
}

export function MainNavFullAnonymous(props: Props) {
  const { hasSecondaryNav, partnerName, partnerLogoUrl } = props;

  return (
    <Box
      height="64px"
      backgroundColor="#333333"
      display="grid"
      gridTemplateColumns="minmax(0px, 1fr) minmax(300px, 1120px) minmax(0px, 1fr)"
      justifyContent="stretch"
      alignItems="center"
      position="sticky"
      top="0"
      zIndex={999}
      marginBottom={hasSecondaryNav === true ? 0 : 2}
      boxShadow={hasSecondaryNav === true ? undefined : "2px 0px 12px rgba(0, 0, 0, 0.6)"}
    >
      <Box gridColumn="1">
        <Box paddingLeft={4}>
          {!partnerLogoUrl && (
            <>
              <Text marginLeft={2} color="white">
                <AppIcon icon={faChartLine} standardRightMargin />
                {partnerName ?? "Reporting Smarts"}
              </Text>
            </>
          )}
          {partnerLogoUrl && (
            <Image objectFit="contain" src={partnerLogoUrl} maxHeight={50} maxWidth={200} crossOrigin="" />
          )}
        </Box>
      </Box>
    </Box>
  );
}
