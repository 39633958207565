import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import ContentLoader from "react-content-loader";
import { Text, Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/react";
import { LOADER_TYPE } from "../constants/constants";

interface Props {
  large?: boolean;
  message?: string;
  delay?: number;
  type?: string;
}

interface FullViewLoaderProps {
  message?: string;
}

interface State {
  showLoader: boolean;
}

interface Style {
  opacity: number;
}

interface TableLoaderProps {
  key: number;
  style: Style;
}

const TableRow = (props: TableLoaderProps) => {
  const random = Math.random() * (1 - 0.7) + 0.7;
  return (
    <ContentLoader
      height={20}
      width={1060}
      speed={2}
      foregroundColor="#d9d9d9"
      backgroundColor="#ecebeb"
      style={props.style}
    >
      <rect x="34" y="13" rx="3" ry="3" width={200 * random} height="5" />
      <rect x="300" y="13" rx="3" ry="3" width={78 * random} height="5" />
      <rect x="450" y="13" rx="3" ry="3" width={78 * random} height="5" />
      <rect x="603" y="13" rx="3" ry="3" width={78 * random} height="5" />
      <rect x="755" y="13" rx="3" ry="3" width={117 * random} height="5" />
      <rect x="938" y="13" rx="3" ry="3" width={83 * random} height="5" />
    </ContentLoader>
  );
};

const TableLoader = () => {
  return (
    <>
      {Array(5)
        .fill("")
        .map((e, i) => (
          <TableRow key={i} style={{ opacity: Number(2 / i + 1) }} />
        ))}
    </>
  );
};

const CardContentLoader = () => (
  <ContentLoader height={80} width={400} speed={2}>
    <rect x="0" y="0" rx="3" ry="3" width="250" height="3" />
    <rect x="20" y="10" rx="3" ry="3" width="220" height="3" />
    <rect x="20" y="20" rx="3" ry="3" width="170" height="3" />
    <rect x="0" y="30" rx="3" ry="3" width="250" height="3" />
    <rect x="20" y="40" rx="3" ry="3" width="200" height="3" />
    <rect x="20" y="50" rx="3" ry="3" width="80" height="3" />
  </ContentLoader>
);

function handleClose() {
  // no-op
}

const FullViewLoader = ({ message }: FullViewLoaderProps) => (
  <Modal isOpen={true} isCentered={true} onClose={handleClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalBody>
        <FontAwesomeIcon icon={faCircleNotch} spin size="4x" />
        {message}
      </ModalBody>
    </ModalContent>
  </Modal>
);

class Loader extends Component<Props, State> {
  state: State = {
    showLoader: false,
  };
  private timeout: NodeJS.Timeout | null = null;

  componentDidMount() {
    const { delay } = this.props;
    this.timeout = setTimeout(() => {
      this.setState({ showLoader: true });
    }, delay || 250);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { large, message, type } = this.props;
    const { showLoader } = this.state;
    if (!showLoader) {
      return null;
    }
    if (type === LOADER_TYPE.table) {
      return <TableLoader />;
    }
    if (type === LOADER_TYPE.content) {
      return <CardContentLoader />;
    }
    if (type === LOADER_TYPE.fullView) {
      return <FullViewLoader message={message} />;
    }
    return (
      <Text fontSize={large ? "lg" : "md"}>
        <FontAwesomeIcon icon={faCircleNotch} spin style={{ marginRight: ".5em" }} />
        {message}
      </Text>
    );
  }
}

export default Loader;
