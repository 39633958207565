import { MainNavLink } from "./main-nav-link";
import { Container } from "../container";
import AppLink from "../../app-link";
import { useLocation } from "react-router-dom";
import { getMainNavDefinition } from "../main-nav-definition";
import { MainNavProfile } from "./main-nav-profile";
import { Box, Image, Text, Stack } from "@chakra-ui/react";
import { useMainMenuPartnerLogoQuery } from "../../../generated/graphql";
import AppIcon from "../../app-icon";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

interface NavItemProps {
  name: string;
  isActive?: boolean;
  to: string;
  onClick?: () => void;
}

export const MAIN_NAV_HEIGHT = "64px";

function NavItem(props: NavItemProps) {
  const { name, to, isActive } = props;
  return (
    <Box>
      <MainNavLink to={to} style={{ color: isActive ? "white" : "#858585" }}>
        {name}
      </MainNavLink>
    </Box>
  );
}

interface Props {
  hasSecondaryNav?: boolean;
}

export function MainNavFull(props: Props) {
  const location = useLocation();
  const { hasSecondaryNav } = props;

  const { data } = useMainMenuPartnerLogoQuery({
    fetchPolicy: "cache-and-network",
  });
  const url = data?.customer?.partner?.reportingLogoUrl ?? null;

  const navDefinition = getMainNavDefinition({ analyticsVisible: !!data?.customer?.analyticsReportingInlineFrameUrl });

  return (
    <Box
      height="64px"
      backgroundColor="#333333"
      display="grid"
      gridTemplateColumns="1fr minmax(300px, 1120px) 1fr"
      justifyContent="stretch"
      alignItems="center"
      position="sticky"
      top="0"
      zIndex={999}
      marginBottom={hasSecondaryNav === true ? 0 : 2}
      boxShadow={hasSecondaryNav === true ? undefined : "2px 0px 12px rgba(0, 0, 0, 0.6)"}
    >
      <Box gridColumn="1">
        <Box paddingLeft={4}>
          <AppLink to="/">
            {!url && (
              <>
                <Text marginLeft={2}>
                  <AppIcon icon={faChartLine} standardRightMargin />
                  {data?.customer?.partner.name ?? "Reporting Smarts"}
                </Text>
              </>
            )}
            {url && <Image objectFit="contain" src={url} maxHeight={50} maxWidth={200} crossOrigin="" />}
          </AppLink>
        </Box>
      </Box>
      <Container gridColumn="2" display="flex">
        <Stack isInline spacing={6} shouldWrapChildren>
          {navDefinition.map((navItem) => {
            if (!navItem.visible) {
              return null;
            }
            return (
              <NavItem
                key={navItem.name}
                name={navItem.name}
                to={navItem.to}
                isActive={location.pathname.startsWith(navItem.to)}
              />
            );
          })}
        </Stack>
      </Container>
      <Box color="white" gridColumn="3">
        <MainNavProfile />
      </Box>
    </Box>
  );
}
