import React from "react";

export interface PanelContextValue {
  isCollapsible: boolean;
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

export const PanelContext = React.createContext<PanelContextValue>({
  isCollapsible: false,
  isCollapsed: false,
  toggleCollapse: () => {
    // no-op
  },
});
