import { Heading, FormLabel, Input, FormControl, Stack, FormErrorMessage, Select } from "@chakra-ui/react";
import { StandardLayoutV2 } from "../../components/layout-v2";
import { MainNav } from "../../components/layout-v2";
import { Container } from "../../components/layout-v2/container";
import { PanelGroup, PanelBottom, PanelTop } from "../../components/panels";
import { PrimaryButton, CancelButton } from "../../components/buttons";
import { useUserProfile } from "./use-user-profile";
import { SimpleGrid } from "@chakra-ui/layout";
import { EMAIL_PREFERENCE } from "../../constants/user-email-preferences";
import { PageTitle } from "../../components/page-title";

export function ProfileRoute() {
  const { profileUser, formik } = useUserProfile();

  return (
    <>
      <StandardLayoutV2 pageTitle="Profile">
        <MainNav />
        <Container centerHorizontally>
          <PageTitle>Account Setup</PageTitle>
          <SimpleGrid columns={{ base: 1, xl: 2 }} gridGap={4}>
            <PanelGroup>
              <PanelTop showBottomBorder>
                <Heading size="md">Preferences</Heading>
              </PanelTop>
              <PanelBottom>
                <Stack spacing={4}>
                  <FormControl isReadOnly>
                    <FormLabel>First Name</FormLabel>
                    <Input value={profileUser?.firstName} variant="outline" />
                  </FormControl>
                  <FormControl isReadOnly>
                    <FormLabel>Last Name</FormLabel>
                    <Input value={profileUser?.lastName} variant="outline" />
                  </FormControl>
                  <FormControl isReadOnly>
                    <FormLabel>Email</FormLabel>
                    <Input value={profileUser?.email} variant="outline" />
                  </FormControl>
                  <FormControl isInvalid={!!formik.touched.emailPreference && !!formik.errors.emailPreference}>
                    <FormLabel>Email Preference</FormLabel>
                    <Select
                      id="emailPreference"
                      value={formik.values.emailPreference}
                      variant="outline"
                      onChange={formik.handleChange}
                    >
                      {Object.values(EMAIL_PREFERENCE).map((option) => (
                        <option value={option.id} key={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Select>
                    {<FormErrorMessage>{formik.errors.emailPreference}</FormErrorMessage>}
                  </FormControl>
                  {formik.dirty && (
                    <Stack isInline spacing={2} marginTop={8}>
                      <PrimaryButton onClick={formik.submitForm}>Save</PrimaryButton>
                      <CancelButton onClick={() => formik.resetForm()}>Cancel</CancelButton>
                    </Stack>
                  )}
                </Stack>
              </PanelBottom>
            </PanelGroup>
          </SimpleGrid>
        </Container>
      </StandardLayoutV2>
    </>
  );
}
