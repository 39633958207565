import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import {
  RejectedTopic,
  useContentCalendarReviewTaskWorkspaceQuery,
  useSubmitContentCalendarReviewMutation,
} from "../../../../generated/graphql";
import Loader from "../../../../components/loader";
import { TOAST_DURATION, TOAST_POSITION } from "../../../../constants/constants";
import { Navigate } from "react-router-dom";
import ProposedOrderTopicTable from "./proposed-order-topic-table";
import { PrimaryButton } from "../../../../components/buttons";
import { TaskCompleted } from "../../task-completed";
import { PanelGroup, PanelTop, PanelBottom } from "../../../../components/panels";

interface Props {
  taskId: number;
}

function ContentCalendarReview(props: Props) {
  const { taskId } = props;
  const toast = useToast();
  const navigate = useNavigate();

  const [rejectedTopics, setRejectedTopics] = useState<RejectedTopic[]>([]);

  const { data, loading, error } = useContentCalendarReviewTaskWorkspaceQuery({
    variables: {
      taskId,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [submitContentCalendarReview, { loading: submitLoading }] = useSubmitContentCalendarReviewMutation();

  function onRejected(rejectedTopic: RejectedTopic) {
    setRejectedTopics([
      ...rejectedTopics.filter((x) => x.proposedOrderTopicId !== rejectedTopic.proposedOrderTopicId),
      rejectedTopic,
    ]);
  }

  function onCancel(orderTopicId: number) {
    setRejectedTopics(rejectedTopics.filter((x) => x.proposedOrderTopicId !== orderTopicId));
  }

  async function onSubmit() {
    const response = await submitContentCalendarReview({
      variables: {
        input: {
          taskId,
          customerProposalId: data?.task?.customerProposal?.id ?? 0,
          rejectedTopics,
        },
      },
    });

    if (response?.data?.submitContentCalendarReview?.task?.id) {
      toast({
        title: "Completed",
        description: "The Content Calendar Review was submitted successfully.",
        status: "success",
      });
      navigate("/");
    }
  }

  if (loading) {
    return <Loader type="fullView" message="Loading Task" />;
  }

  if (error) {
    toast({
      title: "Error",
      position: TOAST_POSITION,
      description: error.message,
      status: "error",
      duration: TOAST_DURATION,
      isClosable: true,
    });
    return <Navigate to="/" />;
  }

  if (!data?.task?.customerProposal?.id) {
    toast({
      title: "Error",
      position: TOAST_POSITION,
      description: "Something went wrong retrieving the task. Please try again.",
      status: "error",
      duration: TOAST_DURATION,
      isClosable: true,
    });
    return <Navigate to="/" />;
  }

  if (data.task.status === "completed") {
    return <TaskCompleted />;
  }

  return (
    <PanelGroup>
      <PanelTop textAlign="right" showBottomBorder>
        <PrimaryButton mt={2} onClick={onSubmit} isLoading={submitLoading}>
          {rejectedTopics.length === 0 ? "Approve" : "Submit"}
        </PrimaryButton>
      </PanelTop>
      <PanelBottom containsTable>
        <ProposedOrderTopicTable
          proposedOrderTopics={data?.task?.customerProposal?.proposedOrderTopics ?? []}
          rejectedTopics={rejectedTopics}
          onRejected={onRejected}
          onCancel={onCancel}
        />
      </PanelBottom>
    </PanelGroup>
  );
}

export default ContentCalendarReview;
