import { CancelButton, PrimaryButton, PrimaryIconButton } from "../../../../components/buttons";
import { TaskCompleted } from "../../task-completed";
import { PanelGroup, PanelTop, PanelBottom, PanelMiddle } from "../../../../components/panels";
import { useContentStrategyReview } from "./content-strategy-review-hook";
import Loader from "../../../../components/loader";
import { LOADER_TYPE, MMM_D_YYYY } from "../../../../constants/constants";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../../../components/table";
import { FormattedDate } from "../../../../components/formatted-date";
import { faExclamationTriangle, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Flex, Heading, Menu, MenuButton, MenuItem, MenuList, Stack, Text, Textarea } from "@chakra-ui/react";
import AppIcon from "../../../../components/app-icon";
import { AppColors } from "../../../../core/custom-theme";
import { RichContent } from "../../../../components/rich-content";

interface Props {
  taskId: number;
}

export function ContentStrategyReview(props: Props) {
  const {
    loading,
    orderContentStrategies,
    task,
    customerProposal,
    handleSubmit,
    isSubmitting,
    cancelRequestRevision,
    handleRequestRevision,
    idBeingEdited,
    saveRevisionRequest,
    comment,
    setComment,
    revisionRequests,
    removeRevisionRequest,
    handleDownloadCSV,
  } = useContentStrategyReview(props);

  if (loading || !task) {
    return <Loader type={LOADER_TYPE.table} />;
  }

  if (task.status === "completed") {
    return <TaskCompleted />;
  }

  return (
    <Stack spacing={4}>
      <Text>
        Below you’ll find the topics and keywords we have planned for your upcoming content orders. Please review each
        topic. If you would like to have any of the proposed topics or keywords changed, click the pencil next to the
        corresponding order, and provide details on what you would like changed. After you have reviewed each content
        order, click the button which will either approve the proposed items or submit the revisions you requested for
        further changes by the content team.
      </Text>
      <PanelGroup>
        <PanelTop textAlign="right" showBottomBorder>
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="md">Content Roadmap</Heading>
            <Stack isInline spacing={2} mt={2}>
              <PrimaryButton onClick={handleDownloadCSV}>Export CSV</PrimaryButton>
              <PrimaryButton onClick={handleSubmit} isLoading={isSubmitting}>
                {revisionRequests.length === 0 ? "Approve All" : "Submit Revision Requests"}
              </PrimaryButton>
            </Stack>
          </Flex>
        </PanelTop>
        {customerProposal?.summary && (
          <PanelMiddle>
            <RichContent content={customerProposal.summary} />
          </PanelMiddle>
        )}
        <PanelBottom containsTable>
          <Table isEmpty={orderContentStrategies.length === 0} isLoading={loading}>
            <TableHead>
              <TableRow key="header">
                <TableHeaderCell>Work Type</TableHeaderCell>
                <TableHeaderCell>Topic</TableHeaderCell>
                <TableHeaderCell>Primary Keyword</TableHeaderCell>
                <TableHeaderCell>Start Date</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {orderContentStrategies.map((orderContentStrategy) => {
                const hasRevision = revisionRequests.find((x) => x.orderContentStrategyId === orderContentStrategy.id);
                const isBeingRevised = idBeingEdited === orderContentStrategy.id;
                return (
                  <>
                    <TableRow key={orderContentStrategy.id}>
                      <TableCell hideBottomBorder={isBeingRevised}>
                        {orderContentStrategy.order?.workType ?? "-"}
                      </TableCell>
                      <TableCell hideBottomBorder={isBeingRevised}>{orderContentStrategy.topic ?? "-"}</TableCell>
                      <TableCell hideBottomBorder={isBeingRevised}>
                        {orderContentStrategy.customerKeyword?.displayName ?? "-"}
                      </TableCell>
                      <TableCell hideBottomBorder={isBeingRevised}>
                        <FormattedDate format={MMM_D_YYYY} date={orderContentStrategy.order?.startDate} />
                      </TableCell>
                      <TableCell hideBottomBorder={isBeingRevised}>
                        <Stack isInline spacing={2} alignItems="center">
                          {hasRevision && <AppIcon color={AppColors.red} icon={faExclamationTriangle} />}
                          {!hasRevision && (
                            <PrimaryIconButton
                              icon={faPencilAlt}
                              actionName="Options"
                              onClick={() => {
                                handleRequestRevision(orderContentStrategy.id);
                              }}
                            />
                          )}
                          {hasRevision && (
                            <Menu>
                              <MenuButton>
                                <PrimaryIconButton icon={faPencilAlt} actionName="Options" />
                              </MenuButton>
                              <MenuList>
                                <MenuItem
                                  onClick={() => {
                                    handleRequestRevision(orderContentStrategy.id);
                                  }}
                                >
                                  {hasRevision ? "Edit Revision Request" : "Request Revision"}
                                </MenuItem>
                                {hasRevision && (
                                  <MenuItem
                                    onClick={() => {
                                      removeRevisionRequest(orderContentStrategy.id);
                                    }}
                                  >
                                    Cancel Revision Request
                                  </MenuItem>
                                )}
                              </MenuList>
                            </Menu>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                    {isBeingRevised && (
                      <TableRow key={`${orderContentStrategy.id}-edit`}>
                        <TableCell colSpan={5}>
                          <Stack spacing={4}>
                            <Textarea
                              placeholder="Explain what needs to change"
                              autoFocus
                              value={comment}
                              onChange={(event) => setComment(event.target.value)}
                            />
                            <Stack isInline spacing={2}>
                              <PrimaryButton onClick={saveRevisionRequest}>Save</PrimaryButton>
                              <CancelButton onClick={cancelRequestRevision}>Cancel</CancelButton>
                            </Stack>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </PanelBottom>
      </PanelGroup>
    </Stack>
  );
}
