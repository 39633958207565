import { Component } from "react";

import Login from "./login";
import { GoogleOAuthProvider } from "@react-oauth/google";

export class LoginContainer extends Component {
  render() {
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}>
        <Login />
      </GoogleOAuthProvider>
    );
  }
}
