import { Heading } from "@chakra-ui/react";

interface Props {
  children: React.ReactNode;
}

export function PageTitle(props: Props) {
  const { children } = props;
  return (
    <Heading as="h1" size="lg" fontWeight="bold" marginY={4}>
      {children}
    </Heading>
  );
}
