import { useToast } from "@chakra-ui/react";
import { useContext } from "react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useUserProfileQuery, useUpdateUserMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { AuthContext } from "../../core/auth-manager";
import { EMAIL_PREFERENCE } from "../../constants/user-email-preferences";

interface FormValues {
  emailPreference: string;
}

const validationSchema = yup.object().shape({
  emailPreference: yup.string().label("Email Preference").notRequired().nullable(false),
});

export function useUserProfile() {
  const context = useContext(AuthContext);
  const user = context.viewer?.user;
  const toast = useToast();
  const [updateUser] = useUpdateUserMutation();

  const queryResult = useUserProfileQuery({
    fetchPolicy: "network-only",
    variables: {
      id: user?.id ?? 0,
    },
    skip: !user?.id,
  });

  const profileUser = queryResult.data?.user;

  async function handleFormSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      if (!user?.id) {
        toast({ title: "Error", description: "User id is not available", status: "error" });
        return;
      }

      const response = await updateUser({
        variables: {
          input: {
            userId: user?.id,
            emailPreference: values.emailPreference,
          },
        },
      });

      if (response.data?.updateUser.user?.id) {
        toast({ title: "Success", description: "User preference updated successfully!", status: "success" });
        await queryResult.refetch();
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      emailPreference: profileUser?.emailPreference ?? EMAIL_PREFERENCE.ALL_EMAILS.id,
    },
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  return {
    loading: queryResult.loading,
    formik,
    user,
    profileUser,
  };
}
